import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {County} from '@ee/common/models';

@Injectable({ providedIn: 'root' })
export class CountyService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getCountyById(id: string): Observable<County | undefined> {
    return this.http.get<County | undefined>(`${this.environment.api_prefix}api/counties/${id}`);
  }

  getCountiesByState(state: string): Observable<County[]> {
    return this.http.get<County[]>(`${this.environment.api_prefix}api/counties/state/${state}`);
  }

  getCountiesByZipCode(zip_code: string): Observable<County[]> {
    return this.http.get<County[]>(`${this.environment.api_prefix}api/counties/zip/${zip_code}`);
  }

  updateCounty(county: County): Observable<County> {
    return this.http.put<County>(`${this.environment.api_prefix}api/counties/${county.id}`, county);
  }
}
