import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IpeComponent} from './ipe.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {MatIconModule} from '@angular/material/icon';
import {CustomFieldModule} from '@ee/common/custom-field';


@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    MatIconModule,
    CustomFieldModule
  ],
  declarations: [IpeComponent],
  exports: [IpeComponent]
})
export class IpeModule {}
