import {Auditing} from './auditing.model';
import {CustomField} from './custom-field.model';
import {ConfirmDialogModel} from './confirm-dialog.model';
import {Change} from './change.model';
import {EvictionUpdateType} from '@ee/common/enums';

export interface EvictionUpdate extends Auditing {
  id: string;
  update_type: EvictionUpdateType;
  amount: number;
  comment: string;
  step: number;
  step_name: string;
  date_started: Date;
  date_completed: Date;
  date_estimated: Date;
  actual_days_diff: number;
  estimated_days_diff: number;
  old_value: any;
  new_value: any;
  custom_fields?: CustomField[];
  case_detail_changes?: Change[];
  additional_data?: { [key: string]: any};
}

export function EvictionUpdateConfirmationMessage() {
  return {
    title: 'Are you sure?',
    description: 'This will close the eviction. Are you sure you wish to continue?'
  } as ConfirmDialogModel;
}
