import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  BaseTask,
  CaseSettings,
  ClientSettings,
  ConfirmDialogModel,
  County,
  CountyFeeSetting,
  DocumentTemplate,
  EvictionUpdateConfirmationMessage,
  StepCompletionModel,
  Tenant,
  User,
  WorkflowStep,
} from '@ee/common/models';
import {lastValueFrom, Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {CaseTaskEditorType, CaseTaskOperation, FlatFeeType, TaskType} from '@ee/common/enums';
import {ClientService, CountyFeeSettingsService, EvictionService, FormService, UserService} from '@ee/common/services';
import {generateCourtTaskForm} from '@ee/common/forms';
import {generateAttorneyFeeBillable, generateCountyFeeBillable, GenerateStepCompletedForm} from './step-completed.form';
import {CaseTaskEditorDialogComponent} from '@ee/common/tasks';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CdkDragDrop} from '@angular/cdk/drag-drop';

@Component({
  selector: 'ee-complete-workflow-step',
  template: `
    <div *ngIf="form" class="main-step-wrapper" [formGroup]="form">
      <div *ngIf="!hideLabel || !workflowStepTask || showScheduleNewCourtDateButton"
           class="mb-4 label flex flex-col md:flex-row justify-stretch items-stretch md:justify-end md:items-center">
        <div class="md:flex-1" *ngIf="!hideLabel">Fill out the form and submit to complete the current step.</div>
        <div *ngIf="(!workflowStepTask && showWorkflowStepScheduler) || showScheduleNewCourtDateButton">
          <ee-workflow-task-info *ngIf="!workflowStepTask && showWorkflowStepScheduler"
                                 [users]="userSuggestions" (onSave)="onSaveTask.emit($event)"></ee-workflow-task-info>
          <a *ngIf="showScheduleNewCourtDateButton" class="ee__link font-medium text-sm" (click)="addCourtDateForm()">
            Schedule New Court Date
          </a>
        </div>
      </div>
      <div class="main-step" [ngClass]="{'vertical': forceVertical}">
        <div class="step-completion-fields">
          <div class="custom-fields mb-2" *ngIf="customFieldsArray?.length">
            <ee-custom-field class="mb-2" *ngFor="let fieldForm of customFieldsArray?.controls"
                             [form]="$any(fieldForm)"></ee-custom-field>
          </div>
          <div class="highlighted-box mb-4" *ngIf="hasBillableItems">
            <div class="highlighted-box-label">Auto-Billables</div>
            <ee-workflow-step-billables [overrideAddBillable]="billablesVisibility === 'visible'"
                                        [billableItemsArray]="billableItemsArray"
                                        [workflowStep]="this.nextStep">
            </ee-workflow-step-billables>
          </div>

          <div class="highlighted-box mb-4" *ngIf="hasDocumentTemplates">
            <div class="highlighted-box-label">Generate Documents</div>
            <ee-document-selector [vertical]="true"
                                  [visibleDocumentTemplates]="$any(form.get('document_templates'))"
                                  [hiddenDocumentTemplates]="$any(form.get('hidden_document_templates'))"/>
          </div>
          <div *ngIf="(!hideComments || !hideNotes) && (form.get('comment') || form.get('notes'))"
               class="flex flex-col justify-stretch items-stretch">
            <mat-form-field *ngIf="!hideComments && form.get('comment')"
                            class="mb-4 compact" appearance="fill">
              <mat-label>Save a message for the client</mat-label>
              <textarea matInput rows="2" formControlName="comment"></textarea>
            </mat-form-field>
            <mat-form-field *ngIf="!hideNotes && form.get('notes')"
                            appearance="fill" class="compact">
              <mat-label>Save a note visible only to your firm</mat-label>
              <textarea matInput rows="2" formControlName="notes"></textarea>
            </mat-form-field>
          </div>
        </div>
        <div class="workflow-generators">

          @if (!!workflowStepTask) {
            <ee-workflow-task class="mb-4" [task]="workflowStepTask" [users]="userSuggestions" (onDelete)="onDeleteTask.emit($event)"
                              (onUpdateTask)="onUpdateTask.emit($event)"></ee-workflow-task>
          }
          <div class="highlighted-box mb-4" *ngIf="hasJudgementForm">
            <div class="highlighted-box-label">
              <div>Judgment: {{ judgementResultForm.get('name').value }}</div>
              <div>
                {{ judgementResultForm.get('due_date').value | dateTimeFromIso | dateTimeToFormat:'MMM d, yyyy' }}

                <span class="ml-1" *ngIf="judgementResultForm.get('legacy').value && !!judgementResultForm.get('legacy_court_time').value">
                  {{ judgementResultForm.get('legacy_court_time').value }}
                </span>

                <span class="ml-1" *ngIf="!!judgementResultForm.get('show_time').value && !judgementResultForm.get('legacy').value">
                  {{ judgementResultForm.get('due_date').value | dateTimeFromIso | dateTimeToFormat:'t' }}
                </span>
              </div>
            </div>
            <ee-court-judgement class="section-wrapper" [form]="judgementResultForm" [hideComment]="true"
                                [caseSettings]="caseSettings"></ee-court-judgement>
          </div>
          <div class="highlighted-box mb-4" *ngIf="hasCourtDateForm" [ngClass]="{'mb-4': !forceVertical && !hideTasks}">
            <div class="highlighted-box-label">
              <span>New Court Task</span>
              <span *ngIf="showRemoveNewCourtDateButton" (click)="removeCourtDateForm()" class="remove">
                Remove
              </span>
            </div>
            <ee-task-form class="mb-2" [taskForm]="courtDateForm"></ee-task-form>
          </div>

          <div class="task-list" *ngIf="!hideTasks">
            <div class="flex flex-row justify-between items-end mb-2 w-full">
              <div class="task-list-label">Tasks</div>
              <div class="task-list-description">

              </div>
            </div>
            @if (!!caseTasks?.length) {
              <div class="task-list-wrapper" cdkDropList (cdkDropListDropped)="taskDrop($event)">
              @for (task of caseTasks; track task) {
                <ee-case-task cdkDrag [task]="task" [users]="userSuggestions" [hideBorder]="true"
                              (onMarkCompleted)="onTaskMarkedCompleted.emit(task.id)" (onEdit)="editTask(task)"></ee-case-task>
              }
                <div class="task-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="updating-task-list" *ngIf="updatingTaskList">Updating...</div>
              </div>
            } @else {
              <div class="font-medium text-center w-full flex items-center justify-center flex-1">No tasks have been added.</div>
            }
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./complete-workflow-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CompleteWorkflowStepComponent implements OnInit, OnDestroy, OnChanges {
  userSuggestions: User[] = [];
  forms$: Observable<DocumentTemplate[]>;
  evictionUpdateMessage: ConfirmDialogModel;
  hasDocumentTemplates = false;
  hasCourtDateForm = false;
  hasJudgementForm = false;
  hasBillableItems = false;
  updatingTaskList = false;
  form: UntypedFormGroup;
  private subs: Subscription[] = [];

  public message = ' ';

  @Input({required: true}) caseSettings: CaseSettings;
  @Input({required: true}) nextStep: WorkflowStep;
  @Input({required: false}) clientId: string | null;
  @Input({required: false}) caseId: string | null;
  @Input({required: false}) hideComments = false;
  @Input({required: false}) hideNotes = false;
  @Input({required: false}) nextCourtTask: BaseTask | null;
  @Input({required: false}) county: County | null;
  @Input({required: false}) tenants: Tenant[] = [];
  @Input({required: false}) countyFeeSetting: CountyFeeSetting;
  @Input({required: false}) parentForm: UntypedFormGroup | null;
  @Input({required: false}) hideLabel = false;
  @Input({required: false}) workflowStepTask: BaseTask | null;
  @Input({required: false}) caseTasks: BaseTask[] | null;
  @Input({required: false}) forceVertical = false;
  @Input({required: false}) hideTasks = false;
  @Input({required: false}) forceJudgementPlanCompletion = false;
  @Input({required: false}) forceAddCourtTaskButton = false;
  @Input({required: false}) showWorkflowStepScheduler = false;
  @Input({required: false}) documentTemplateVisibility: 'visible' | 'hidden' | 'default' = 'default';
  @Input({required: false}) billablesVisibility: 'visible' | 'hidden' | 'default' = 'default';
  @Output() onSave = new EventEmitter<StepCompletionModel>();

  @Output() public onDeleteTask = new EventEmitter<BaseTask>();
  @Output() public onUpdateTask = new EventEmitter<BaseTask>();
  @Output() public onTaskMarkedCompleted = new EventEmitter<string>();
  @Output() public onSaveTask = new EventEmitter<BaseTask>();
  @Output() public onUpdateTaskOrder = new EventEmitter<{ [key: string]: number }>();

  constructor(private fb: UntypedFormBuilder,
              public formService: FormService,
              public dialog: MatDialog,
              public evictionService: EvictionService,
              public userService: UserService,
              public clientService: ClientService,
              public countyFeeSettingsService: CountyFeeSettingsService,
              public snackBar: MatSnackBar,
              public changeDetectorRef: ChangeDetectorRef) {
    this.evictionUpdateMessage = EvictionUpdateConfirmationMessage();
  }

  ngOnInit() {
    this.forms$ = this.formService.getAllForms();
    this.subs.push(this.userService.getUserSuggestions().subscribe((users) => {
      this.userSuggestions = users;
      this.changeDetectorRef.detectChanges();
    }));
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const changeKeys = Object.keys(changes);
    if (changeKeys.length === 1 && changeKeys[0] === 'county') {
      this.replaceCountyBillableItem(this.nextStep, changes['county'].currentValue);
      this.changeDetectorRef.detectChanges();
    } else if ((changeKeys.length === 2 || changeKeys.length === 1) &&
      (changeKeys.indexOf('caseTasks') > -1 || changeKeys.indexOf('workflowStepTask') > -1)) {
      // updating task, don't perform a complete refresh
      this.updatingTaskList = false;
      this.changeDetectorRef.detectChanges();
    } else {
      this.hasDocumentTemplates = false;
      this.hasCourtDateForm = false;
      this.hasJudgementForm = false;
      this.hasBillableItems = false;
      if (this.county && this.countyFeeSetting?.county_id !== this.county?.id) {
        this.countyFeeSetting = await lastValueFrom(this.countyFeeSettingsService.getByCounty(this.county));
      }
      this.subs.push(this.clientService.getClientSettings(this.clientId).subscribe((clientSettings: ClientSettings | null) => {
        this.form = GenerateStepCompletedForm(this.fb, this.nextStep, this.county, this.tenants ?? [], this.countyFeeSetting,
          clientSettings, this.nextCourtTask, this.forceJudgementPlanCompletion);
        if (this.parentForm) {
          this.parentForm.setControl('step_completion_details', this.form);
        }
        this.hasDocumentTemplates = (this.nextStep?.document_templates?.length > 0 ||
            this.nextStep?.hidden_document_templates?.length > 0 || this.documentTemplateVisibility === 'visible') &&
          this.documentTemplateVisibility !== 'hidden';
        this.hasBillableItems = (this.nextStep?.billable_items?.length > 0 || this.billablesVisibility === 'visible') &&
          this.billablesVisibility !== 'hidden';
        this.hasCourtDateForm = this.nextStep?.show_new_court_date_form && !!this.courtDateForm;
        this.hasJudgementForm = !!this.nextCourtTask && !this.nextCourtTask?.completed_date && !!this.judgementResultForm;
        this.changeDetectorRef.detectChanges();
      }));
    }
  }

  get customFieldsArray(): UntypedFormArray {
    return this.form?.get('custom_fields') as UntypedFormArray;
  }

  get billableItemsArray(): UntypedFormArray {
    return this.form?.get('billable_items') as UntypedFormArray;
  }

  get courtDateForm(): UntypedFormGroup | null {
    return this.form?.get('new_court_task') as UntypedFormGroup | null;
  }

  get judgementResultForm(): UntypedFormGroup | null {
    return this.form?.get('judgement_result') as UntypedFormGroup | null;
  }

  get showScheduleNewCourtDateButton(): boolean {
    return (this.forceAddCourtTaskButton || this.nextStep?.show_new_court_date_form) && !this.nextStep?.new_court_date_required && !this.hasCourtDateForm;
  }

  get showRemoveNewCourtDateButton(): boolean {
    return (this.forceAddCourtTaskButton || this.nextStep?.show_new_court_date_form) && !this.nextStep?.new_court_date_required;
  }

  addCourtDateForm() {
    let taskName = 'Court Appearance';
    if (this.hasJudgementForm) {
      taskName = 'Followup ' + taskName;
    }
    this.form.addControl('new_court_task', generateCourtTaskForm(this.fb, null, taskName));
    this.hasCourtDateForm = true;
  }

  removeCourtDateForm() {
    this.form.removeControl('new_court_task');
    this.hasCourtDateForm = false;
  }

  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.message = 'Please fix the errors in the form to complete the step.';
      this.snackBar.open(this.message, 'Dismiss', {
        duration: 3000
      });
      return;
    } else if (this.caseTasks?.length && this.caseTasks.some(task => task.type === TaskType.CASE_STEP_TASK && task.required && !task.marked_completed_date)) {
      this.message = 'Please complete all required tasks before completing the step.';
      this.snackBar.open(this.message, 'Dismiss', {
        duration: 3000
      });
      return;
    }

    const value = this.form.value as StepCompletionModel;
    value.case_id = this.caseId;
    value.step = this.nextStep?.step;
    this.onSave.emit(value);
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s.unsubscribe());
  }

  editTask(task: BaseTask) {
    const dialogRef = this.dialog.open(CaseTaskEditorDialogComponent, {
      data: { editorType: CaseTaskEditorType.TaskEdit, taskDetails: task, type: task.type },
      width: '500px',
      maxWidth: '500px',
      disableClose: false
    });
    this.subs.push(
      dialogRef.afterClosed().subscribe((result: {form: UntypedFormGroup | undefined,  operation: CaseTaskOperation}) => {
        if (result?.operation === CaseTaskOperation.Edit) {
          this.updatingTaskList = true;
          this.onUpdateTask.emit(result.form.value);
        } else if (result?.operation === CaseTaskOperation.Delete) {
          this.updatingTaskList = true;
          this.onDeleteTask.emit(task);
        }
      })
    );
  }

  taskDrop(event: CdkDragDrop<string[]>) {
    this.updatingTaskList = true;
    // generate new order values based on the new order and push to the event
    const newOrder: { [key: string]: number } = {};

    // update the order of the tasks
    this.caseTasks?.forEach((task, index) => {
      if (index === event.previousIndex) {
        newOrder[task.id] = this.caseTasks[event.currentIndex].order;
      } else if (index >= event.currentIndex && index < event.previousIndex) {
        newOrder[task.id] = this.caseTasks[index + 1].order;
      } else if (index <= event.currentIndex && index > event.previousIndex) {
        newOrder[task.id] = this.caseTasks[index - 1].order;
      } else {
        newOrder[task.id] = task.order;
      }
    });

    this.onUpdateTaskOrder.emit(newOrder);
  }

  async replaceCountyBillableItem(nextStep: WorkflowStep, county: County) {
    // remove item of type
    this.billableItemsArray.controls.forEach((item) => {
      if (item.get('flat_fee_type')?.value === FlatFeeType.COUNTY_FEE) {
        this.billableItemsArray.removeAt(this.billableItemsArray.controls.indexOf(item));
      }
    });

    // find if workflow step requires county fee
    const definedBillable = nextStep?.billable_items.find((item) => item.flat_fee_type === FlatFeeType.COUNTY_FEE);
    if (definedBillable) {
      this.countyFeeSetting = await lastValueFrom(this.countyFeeSettingsService.getByCounty(county));
      if (this.countyFeeSetting) {
        this.billableItemsArray.push(generateCountyFeeBillable(this.fb, this.countyFeeSetting, this.county, this.tenants));
        this.changeDetectorRef.detectChanges();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  async replaceAttorneyBillableItem(nextStep: WorkflowStep, clientId: string | null) {
    // remove item of type
    this.billableItemsArray.controls.forEach((item) => {
      if (item.get('flat_fee_type')?.value === FlatFeeType.CLIENT_ATTORNEY_FEE) {
        this.billableItemsArray.removeAt(this.billableItemsArray.controls.indexOf(item));
      }
    });

    // find if workflow step requires attorney fee
    const definedBillable = nextStep?.billable_items.find((item) => item.flat_fee_type === FlatFeeType.CLIENT_ATTORNEY_FEE);
    if (definedBillable && clientId) {
      const clientSettings = await lastValueFrom(this.clientService.getClientSettings(clientId));
      this.billableItemsArray.push(generateAttorneyFeeBillable(this.fb, definedBillable, clientSettings));
      this.changeDetectorRef.detectChanges();
    }
    this.changeDetectorRef.detectChanges();
  }

}
