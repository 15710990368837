import {Injectable, OnDestroy} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {Eviction, IntakeForm, IntakeFormSection, IntakeOption, Organization, Tenant, User, Workflow, WorkflowStep} from '@ee/common/models';
import {ClientService, IntakeFormService} from '@ee/common/services';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  GenerateEvictionClaimsForm, GenerateEvictionDocumentForm, GenerateEvictionLeaseTermsForm,
  GenerateEvictionPropertyForm,
  GenerateEvictionTenantForm, GenerateInternalFieldsForm,
  GenerateSplitEvictionForm,
  WorkflowDetailsForm
} from '@ee/common/forms';
import {AccountType, IntakeFormFields, IntakeFormSections, IntakeFormType} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class IntakeEvictionFormService implements OnDestroy {
  private subs: Subscription[] = [];
  public splitForm: UntypedFormGroup;
  public intakeForm: IntakeForm | undefined;
  public selectedWorkflow: Workflow | undefined = undefined;
  public workflows: Workflow[] = [];
  public workflows$: Subject<Workflow[]> = new Subject<Workflow[]>();
  public plaintiffHistory: string[] = [];
  public defaultWorkflowId: string | undefined;
  public loggedInOrgId: string | undefined;
  public loggedInOrgType: AccountType | undefined;
  public selectedClient: Organization | undefined;
  public selectedClient$: Subject<Organization> = new Subject<Organization>();
  public attorneyId: string | undefined;
  public isPublicIntakeForm: boolean = false;
  public isInitialized = false;
  public isInitialized$ = new Subject<boolean>();
  public selectedAttorneyId$ = new Subject<string>();
  public evictionDetails: Eviction | undefined = undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private intakeFormService: IntakeFormService,
    private clientService: ClientService
  ) {
  }

  async initialize(isPublicIntakeForm: boolean, attorneyId: string, loggedInUser: User | null, loggedInOrg: Organization | null) {
    if (this.isInitialized) {
      return;
    }
    this.loggedInOrgType = loggedInOrg?.type;
    this.loggedInOrgId = loggedInOrg?.id;
    this.attorneyId = attorneyId;
    this.isPublicIntakeForm = isPublicIntakeForm;
    this.defaultWorkflowId = loggedInOrg?.default_workflow_id;
    this.splitForm = GenerateSplitEvictionForm(this.fb, this.loggedInOrgType, isPublicIntakeForm);
    const workflowPatch: any = {attorney_id: attorneyId};

    if (!isPublicIntakeForm && this.loggedInOrgType === AccountType.CLIENT) {
      workflowPatch.user_details = loggedInUser;
      workflowPatch.client_details = loggedInOrg;
      this.selectedClient = loggedInOrg;
      this.defaultWorkflowId = undefined;
    }
    this.workflowDetails.patchValue(workflowPatch);

    if (!isPublicIntakeForm && this.loggedInOrgType === AccountType.CLIENT) {
      // let's treat a client that was invited by an attorney as exclusive to attorney unless they're a pro user
      await this.setupForms();
    }

    this.selectedAttorneyId$.next(attorneyId);
    this.selectedClient$.next(this.selectedClient);
    this.isInitialized = true;
    this.isInitialized$.next(true);
  }

  public clearForm() {
    this.splitForm.reset();
    this.removeForms();
    this.isInitialized = false;
    this.isInitialized$.next(false);
  }

  public updateWorkflow(workflows: Workflow[]) {
    if (workflows?.length) {
      this.selectedWorkflow = workflows[0];
      if (this.defaultWorkflowId) {
        this.selectedWorkflow = workflows.find(w => w.id === this.defaultWorkflowId);
      }
      this.workflowDetails.patchValue({workflow_id: this.selectedWorkflow.id});
      this.workflows = workflows;
      this.workflows$.next(workflows);
    }
  }

  public updateClient(client: Organization) {
    this.workflowDetails.patchValue({client_details: client});
    this.selectedClient = client;
    this.selectedClient$.next(client);
  }

  public updateAttorneyUser(attorneyUserId: string) {
    this.workflowDetails.patchValue({selected_attorney_user_id: attorneyUserId});
  }

  public updateAttorney(attorneyId: string) {
    this.workflowDetails.patchValue({attorney_id: attorneyId});
    this.attorneyId = attorneyId;
    this.selectedAttorneyId$.next(attorneyId);
  }

  public generateEvictionDetails(): Eviction | undefined {
    if (!this.tenantDetails || !this.propertyDetails || !this.claimDetails || !this.leaseTermsDetails || !this.documents) {
      return undefined;
    }
    const tenantDetails = this.tenantDetails.value;
    const propertyDetails = this.propertyDetails.value;
    const claimDetails = this.claimDetails.value;
    const leaseTermsDetails = this.leaseTermsDetails.value;
    const internalDetails = this.internalDetails?.value;

    let internalFields = {};
    if (this.setupInternalFields) {
      internalFields = internalDetails?.custom_fields ?? {};
    }

    // copy custom fields into eviction details
    const evictionDetails = {...tenantDetails, ...propertyDetails, ...leaseTermsDetails, ...claimDetails,
      custom_fields: {...tenantDetails.custom_fields ?? {}, ...propertyDetails.custom_fields ?? {},
        ...leaseTermsDetails.custom_fields ?? {}, ...claimDetails.custom_fields ?? {}, ...internalFields}};

    this.evictionDetails = evictionDetails;
    return evictionDetails;
  }

  public removeForms() {
    this.splitForm.removeControl('tenant_details');
    this.splitForm.removeControl('property_details');
    this.splitForm.removeControl('claim_details');
    this.splitForm.removeControl('lease_terms_details');
    this.splitForm.removeControl('documents');
    this.plaintiffHistory = [];
    this.evictionDetails = undefined;
  }

  public async setupForms() {
    // fully expect attorney id to be specified by the time this method is called
    const attorneyId = this.workflowDetails.get('attorney_id')?.value;
    if (attorneyId) {
      this.subs.push(this.intakeFormService.findByTypeAndOrganizationId(IntakeFormType.EVICTION, attorneyId)
        .subscribe((intakeForm: IntakeForm) => {

          this.intakeForm = intakeForm;

          // setup form
          this.splitForm.addControl('tenant_details',
            GenerateEvictionTenantForm(this.fb, intakeForm.options, this.tenantsSection));

          this.splitForm.addControl('property_details',
            GenerateEvictionPropertyForm(this.fb, intakeForm.options, this.propertySection));

          this.splitForm.addControl('claim_details',
            GenerateEvictionClaimsForm(this.fb, intakeForm.options, this.claimsSection));

          this.splitForm.addControl('lease_terms_details',
            GenerateEvictionLeaseTermsForm(this.fb, intakeForm.options, this.leaseTermsSection));

          if (this.setupInternalFields) {
            this.splitForm.addControl('internal_details',
              GenerateInternalFieldsForm(this.fb, intakeForm.options, this.internalSection));
          }

          this.splitForm.addControl('documents', GenerateEvictionDocumentForm(this.fb, intakeForm));

          this.propertyDetails?.patchValue({company_name: this.selectedClient?.company_name});
        }));
    }

    // find historical list of plaintiffs
    this.plaintiffHistory = await this.clientService.getPlaintiffHistory(
      this.workflowDetails.get('client_details').value?.company_name,
      this.workflowDetails.get('client_details').value?.id,
      this.workflowDetails.get('attorney_id').value,
      this.isPublicIntakeForm);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  get workflowDetails(): WorkflowDetailsForm | null {
    return this.splitForm ? this.splitForm.controls.workflow_details as WorkflowDetailsForm : null;
  }

  get tenantDetails(): UntypedFormGroup | null {
    return this.splitForm ? this.splitForm.controls.tenant_details as UntypedFormGroup : null;
  }

  get tenants(): Tenant[] | null {
    return this.tenantDetails?.controls.tenants.value;
  }

  get propertyDetails(): UntypedFormGroup | null {
    return this.splitForm ? this.splitForm.controls.property_details as UntypedFormGroup : null;
  }

  get claimDetails(): UntypedFormGroup | null {
    return this.splitForm ? this.splitForm.controls.claim_details as UntypedFormGroup : null;
  }

  get leaseTermsDetails(): UntypedFormGroup | null {
    return this.splitForm ? this.splitForm.controls.lease_terms_details as UntypedFormGroup : null;
  }

  get internalDetails(): UntypedFormGroup | null {
    return this.splitForm ? this.splitForm.controls.internal_details as UntypedFormGroup : null;
  }

  get documents(): UntypedFormArray | null {
    return this.splitForm ? this.splitForm.controls.documents as UntypedFormArray : null;
  }

  get options(): Map<string, IntakeOption> | undefined {
    return this.intakeForm?.options;
  }

  get tenantsSection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.TENANT] ?? undefined;
  }

  get propertySection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.PROPERTY] ?? undefined;
  }

  get claimsSection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.CLAIMS] ?? undefined;
  }

  get leaseTermsSection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.LEASE_TERMS] ?? undefined;
  }

  get internalSection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.INTERNAL] ?? undefined;
  }

  get documentSection(): IntakeFormSection | undefined {
    return this.intakeForm?.sections[IntakeFormSections.DOCUMENTS] ?? undefined;
  }

  get intakeStep(): WorkflowStep | undefined {
    return this.selectedWorkflow?.steps.find(s => s.step === 1);
  }

  get evictionReason(): string {
    return this.tenantDetails?.get(IntakeFormFields.EVICTION_REASON)?.value;
  }

  get setupInternalFields(): boolean {
    return this.loggedInOrgType === AccountType.ATTORNEY && this.internalSection?.fields.length > 0;
  }
}
