import {
  Component,
  Input
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IntakeFormSection, IntakeOption} from '@ee/common/models';

@Component({
  selector: 'ee-internal-section',
  template: `
      <div class="section-wrapper" [formGroup]="form">
          <div class="in-row-grid" *ngIf="internalSection?.fields?.length && internalFields">
              <ee-field *ngFor="let f of internalSection?.fields; let i = index"
                        [ngClass]="{'margin-bottom-sm': i < (internalSection.fields.length - 1),
                                    'md:mb-4': i < (internalSection.fields.length - 1),
                                    'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                        [hideFromIntake]="true"
                        [control]="$any(internalFields.controls[f.field])" [field]="f">
              </ee-field>
          </div>
      </div>
  `,
  styles: [`
    @import 'components/color-palette';
    @import '../section-styles';

    .margin-bottom {
      margin-bottom: 1rem;
    }
    .margin-bottom-sm {
      margin-bottom: .5rem;
    }
  `],
  standalone: false
})
export class InternalSectionComponent {
  @Input() demoMode = false;

  @Input() form: UntypedFormGroup;

  @Input() options: Map<string, IntakeOption> | undefined;

  @Input() internalSection: IntakeFormSection | undefined;

  get internalFields(): UntypedFormGroup | undefined {
    return this.form.get('custom_fields') as UntypedFormGroup;
  }
}
