import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import forEach from 'lodash-es/forEach';

export interface IntakeOption {
  show: boolean;
  required: boolean;
  type?: string;
  more_info_link?: string;
  label_override?: string;
  hint?: string;
  hide_from_intake?: boolean;
}

/**
 * Adds validators for form controls per attorney's options/specifications
 *
 * @param form
 * @param options
 */
export function processForm(form: UntypedFormGroup, options: Map<string, IntakeOption>,
  standardValidators: Map<string, ValidatorFn[]> = new Map()): UntypedFormGroup {

  forEach(form.controls, (a: UntypedFormControl, b: string) => {
    const o = options[b] as IntakeOption;
    const validators = standardValidators.get(b) ?? [];

    if (o && o.required && o.show) {
      validators.push(Validators.required);
    }

    a.setValidators(validators);
    a.updateValueAndValidity();
  });

  return form;
}
