// borrowed from https://github.com/merlosy/ngx-material-file-input/blob/master/libs/material-file-input/src/lib/validator/file-validator.ts

import { ValidatorFn, AbstractControl } from '@angular/forms';

export class FileValidator {
  /**
   * Function to control content of files
   *
   * @param bytes max number of bytes allowed
   *
   * @returns
   */
  static maxContentSize(bytes: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const size = control && control.value ? (control.value as File[]).map(f => f.size).reduce((acc, i) => acc + i, 0) : 0;
      const condition = bytes >= size;
      return condition
        ? null
        : {
          maxContentSize: {
            actualSize: size,
            maxSize: bytes
          }
        };
    };
  }
}
