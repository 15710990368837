import {Pipe, PipeTransform} from '@angular/core';
import Fuse from 'fuse.js';

@Pipe({
  name: 'search',
  standalone: false
})
export class SearchPipe implements PipeTransform {
  public transform(value: any, keys: string, term: string) {
    if (!term) {
      return value;
    }
    const fuse = new Fuse(value, {shouldSort: true, findAllMatches: true, keys: keys.split(',')});
    return fuse.search(term).map(r => r.item);
  }
}
