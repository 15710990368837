import { Auditing } from './auditing.model';
import { TaskType, UserRolesType } from '@ee/common/enums';
import { User } from './user.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Change } from './change.model';

export interface BaseTask extends Auditing {
  id?: string;
  name: string;
  case_id?: string;
  description?: string;
  deleted_date?: string;
  deleted_by?: string;
  due_date?: string;
  days_remaining?: number;
  assigned_users?: string[];
  marked_completed_date?: string;
  marked_completed_by?: string;
  completed_by?: string;
  completed_date?: string;
  priority?: number;
  order?: number;
  timezone?: string;
  type: TaskType;
  tags?: string[];
  updates?: string[];
  required?: boolean;
  remove_on_step_completion?: boolean;
  roles?: UserRolesType[];
  workflow_step?: number;
  update_history?: Change[];

  // These are only used in the UI
  assignedUsers?: User[];
}

export type BaseTaskForm = FormGroup<{
  id: FormControl<string | null>;
  name: FormControl<string>;
  description: FormControl<string | null>;
  case_id: FormControl<string | null>;
  due_date: FormControl<string | null>;
  assigned_users: FormControl<string[]>;
  priority: FormControl<number | null>;
  timezone: FormControl<string | null>;
  type: FormControl<TaskType>;
  tags: FormControl<string[]>;
  required: FormControl<boolean | null>;
  remove_on_step_completion: FormControl<boolean | null>;
  roles: FormControl<UserRolesType[] | null>;
}>;

export function GenerateBaseTaskForm(fb: FormBuilder, type: TaskType, value: BaseTask | null = null): BaseTaskForm {
  const form: BaseTaskForm = fb.group({
    id: new FormControl<string | null>(null, { nonNullable: false }),
    name: new FormControl<string>('', Validators.required),
    description: new FormControl<string | null>(null),
    case_id: new FormControl<string | null>(null),
    due_date: new FormControl<string | null>(null),
    assigned_users: new FormControl<string[]>([]),
    priority: new FormControl<number | null>(null),
    timezone: new FormControl<string | null>(null),
    type: new FormControl<TaskType>(type),
    tags: new FormControl<string[]>([]),
    required: new FormControl<boolean | null>(null),
    remove_on_step_completion: new FormControl<boolean | null>(null),
    roles: new FormControl<UserRolesType[] | null>(null)
  });

  if (value) {
    form.patchValue(value);

    if (value.required) {
      form.get('remove_on_step_completion').disable();
    }
  }

  return form;
}
