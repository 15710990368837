import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {AttorneyClientSettings, Organization, PermissionGroup} from '@ee/common/models';
import {AccountType} from '@ee/common/enums';

@Injectable({providedIn: 'root'})
export class OrganizationService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  updateOrganization(organization: Organization): Observable<Organization> {
    return this.http.put<Organization>(`${this.environment.api_prefix}api/organizations`, organization);
  }

  generateAttorneyLogoUrl(attorneyIdentifier: string): string {
    return `${this.environment.api_prefix}api/organizations/attorney/${attorneyIdentifier}/logo`;
  }

  uploadOrganizationLogo(formData: FormData): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/organizations/upload-logo`, formData, {responseType: 'text'});
  }

  getAttorneyByIdentifier(attorneyIdentifier: string): Observable<Organization> {
    return this.http.get<Organization>(
      `${this.environment.api_prefix}api/organizations/attorney/${attorneyIdentifier}`);
  }

  getBillingDescriptionHistory(): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.api_prefix}api/organizations/billable-description-history`);
  }

  updateBillableDescriptionHistory(descriptions: string[]): Observable<string[]> {
    return this.http.put<string[]>(`${this.environment.api_prefix}api/organizations/billable-description-history`, descriptions);
  }

  updateEvictionStatusUpdate(key: string, value: any): Observable<AttorneyClientSettings> {
    return this.http.post<AttorneyClientSettings>(`${this.environment.api_prefix}api/organizations/case-status-update`, {
      key: key,
      value: value
    });
  }

  getOrganizationLogo(orgId: string): Observable<HttpResponse<string>> {
    return this.http.get(`${this.environment.api_prefix}api/organizations/${orgId}/logo`, {
      observe: 'response',
      responseType: 'text'
    });
  }

  getAttorneys(): Observable<Organization[]> {
    return this.http.get<Organization[]>(`${this.environment.api_prefix}api/organizations/attorneys`);
  }

  getDefaultPermissionGroups(type: AccountType): Observable<PermissionGroup[]> {
    return this.http.get<PermissionGroup[]>(`${this.environment.api_prefix}api/organizations/permission-groups/default/${type}`);
  }

  getPermissionGroups(): Observable<PermissionGroup[]> {
    return this.http.get<PermissionGroup[]>(`${this.environment.api_prefix}api/organizations/permission-groups`);
  }

}
