import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

if ((window as any).newrelic) {
  (window as any).newrelic.setCustomAttribute('appName', 'ee-intake');
  (window as any).newrelic.setApplicationVersion(environment.version);
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
