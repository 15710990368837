import {
  FormBuilder, FormControl, FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {AccountType, IntakeFormSections} from '@ee/common/enums';
import {GenerateUserForm, UserForm} from './user.form';
import {GenerateOrganizationForm, OrganizationForm} from './organization.form';
import {
  CustomField,
  IntakeForm,
  IntakeFormSection,
  IntakeOption,
  processForm, StepCompletionModel,
} from '@ee/common/models';
import {CURRENCY, EVICTION_REASONS_CLAIMS_MAP, MAX_FILE_UPLOAD_SIZE} from '@ee/common/constants';
import {FileValidator} from '@ee/common/validators';

export type WorkflowDetailsForm = FormGroup<{
  attorney_id: FormControl<string>;
  user_details: UserForm | null;
  client_details: OrganizationForm | null;
  workflow_id: FormControl<string>;
  selected_attorney_user_id: FormControl<string | null>;
}>

export type SplitEvictionForm = FormGroup<{
  type: FormControl<'Eviction'>;
  workflow_details: WorkflowDetailsForm | null;
  client_id: FormControl<string | null>;
  captcha: FormControl<string | null>;
  note: FormControl<string | null>;
  step_completion_details: FormControl<StepCompletionModel>;
}>

export function GenerateSplitEvictionForm(fb: FormBuilder, accountType?: AccountType, intakeForm = false): SplitEvictionForm {
  return fb.group({
    type: new FormControl<'Eviction'>('Eviction'),
    workflow_details: fb.group({
      attorney_id: new FormControl<string>(null, accountType === AccountType.CLIENT ? [Validators.required] : []),
      user_details: intakeForm ? GenerateUserForm(fb) || null : null,
      client_details: intakeForm
        ? GenerateOrganizationForm(fb, AccountType.CLIENT, true) : null,
      workflow_id: new FormControl<string>(null,accountType === AccountType.ATTORNEY ? [Validators.required] : []),
      selected_attorney_user_id: new FormControl<string | null>(null)
    }),
    client_id: new FormControl<string>(null),
    captcha: new FormControl<string>(null, intakeForm ? [Validators.required] : []),
    note: new FormControl<string>(null),
    step_completion_details: new FormControl<StepCompletionModel>(null)
  });
}

export function GenerateEvictionTenantForm(fb: UntypedFormBuilder,
  options: Map<string, IntakeOption>,
  tenantSection: IntakeFormSection | undefined): UntypedFormGroup {
  const evictionReasons = Array.from(EVICTION_REASONS_CLAIMS_MAP.keys());
  let defaultEvictionReason = evictionReasons.find((k: string) => k === 'Unpaid Rent') as string;
  if (!defaultEvictionReason && evictionReasons.length) {
    defaultEvictionReason = evictionReasons[0];
  }
  const form = fb.group({
    tenants: fb.array([]),
    not_active_military: null,
    applied_for_assistance: null,
    eviction_reason: defaultEvictionReason,
    reason: null
  });

  // add tenant section fields
  if (tenantSection?.fields?.length) {
    const customFields = {};

    tenantSection.fields.forEach((field: CustomField) => {
      customFields[field.field] = [undefined, field.required && !field.hide_from_intake ? [Validators.required] : []];
    });

    form.addControl('custom_fields', fb.group(customFields));
  }

  // add validators per attorney's specifications
  return processForm(form, options);
}

export function GenerateEvictionPropertyForm(fb: UntypedFormBuilder,
  options: Map<string, IntakeOption>,
  propertySection: IntakeFormSection | undefined): UntypedFormGroup {
  const form = fb.group({
    company_name: null,
    property_name: null,
    property_street_address: '',
    property_unit_number: null,
    property_unit_type: null,
    property_city: '',
    property_state: '',
    property_zip_code: null,
    property_type: null,
    county: null,
    property_lat: null,
    property_lng: null,
    district_court_name: null
  });

  const additionalValidators: Map<string, ValidatorFn[]> = new Map<string, ValidatorFn[]>();
  additionalValidators.set('company_name', [Validators.required]);
  additionalValidators.set('property_street_address', [Validators.required]);
  additionalValidators.set('property_city', [Validators.required]);
  additionalValidators.set('property_state', [Validators.required]);
  additionalValidators.set('property_zip_code', [Validators.required]);

  // add property section fields
  if (propertySection?.fields?.length) {
    const customFields = {};

    propertySection.fields.forEach((field: CustomField) => {
      customFields[field.field] = [undefined, field.required && !field.hide && !field.hide_from_intake ? [Validators.required] : []];
    });

    form.addControl('custom_fields', fb.group(customFields));
  }

  // add validators per attorney's specifications
  return processForm(form, options, additionalValidators);
}

export function GenerateEvictionClaimsForm(fb: UntypedFormBuilder, options: Map<string, IntakeOption>,
  claimsSection: IntakeFormSection | undefined): UntypedFormGroup {
  const form = fb.group({
    tenant_total_owed: null,
    past_due_rent: null,
    past_due_rent_history: fb.array([]),
    delinquency_length: null,
    late_fees: null,
    damages: null,
    damages_description: null,
    interest_rate: null,
    interest_start_date: null,
    costs: null,
    civil_recovery_fees: null,
    attorney_fee: null,
    partial_payments: null
  });

  // add dynamic document fields
  const additionalValidators: Map<string, ValidatorFn[]> = new Map<string, ValidatorFn[]>();

  // if (claimsSection?.calculated_field) {
  //   additionalValidators.set('tenant_total_owed', [Validators.pattern(CURRENCY)]);
  // } else {
  additionalValidators.set('tenant_total_owed', [Validators.required, Validators.min(0.01), Validators.pattern(CURRENCY)]);
  // }
  additionalValidators.set('past_due_rent', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('late_fees', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('damages', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('costs', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('civil_recovery_fees', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('attorney_fee', [Validators.pattern(CURRENCY)]);

  // add claims section fields
  if (claimsSection?.fields?.length) {
    const customFields = {};

    claimsSection.fields.forEach((field: CustomField) => {
      customFields[field.field] = [undefined, field.required && !field.hide_from_intake ? [Validators.required] : []];
    });

    form.addControl('custom_fields', fb.group(customFields));
  }

  // add validators per attorney's specifications
  return processForm(form, options, additionalValidators);
}

export function GenerateEvictionLeaseTermsForm(fb: UntypedFormBuilder, options: Map<string, IntakeOption>,
  leaseTermsSection: IntakeFormSection | undefined): UntypedFormGroup {
  const form = fb.group({
    security_deposit: null,
    lease_rent_amount: null,
    lease_rent_due_day: '1st',
    lease_rent_frequency: 'Month',
    lease_terms: null,
    move_in_date: null,
    notice_date: null,
    waiver_of_notice: false
  });

  // add dynamic document fields
  const additionalValidators: Map<string, ValidatorFn[]> = new Map<string, ValidatorFn[]>();
  additionalValidators.set('security_deposit', [Validators.pattern(CURRENCY)]);
  additionalValidators.set('lease_rent_amount', [Validators.pattern(CURRENCY)]);

  // add lease terms section fields
  if (leaseTermsSection?.fields?.length) {
    const customFields = {};

    leaseTermsSection.fields.forEach((field: CustomField) => {
      customFields[field.field] = [undefined, field.required && !field.hide_from_intake ? [Validators.required] : []];
    });

    form.addControl('custom_fields', fb.group(customFields));
  }

  // add validators per attorney's specifications
  return processForm(form, options, additionalValidators);
}

export function GenerateInternalFieldsForm(fb: UntypedFormBuilder, options: Map<string, IntakeOption>,
  internalSection: IntakeFormSection | undefined): UntypedFormGroup {
  const form = fb.group({});

  // add internal section fields
  if (internalSection?.fields?.length) {
    const customFields = {};

    internalSection.fields.forEach((field: CustomField) => {
      customFields[field.field] = [undefined, field.required && !field.hide_from_intake ? [Validators.required] : []];
    });

    form.addControl('custom_fields', fb.group(customFields));
  }
  return form;
}

export function GeneratePastDueHistoryForm(fb: UntypedFormBuilder): UntypedFormGroup {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  return fb.group({
    month: [currentMonth, [Validators.required]],
    year: [currentYear, [Validators.required]],
    amount: [0, [Validators.required, Validators.pattern(CURRENCY), Validators.min(0.01)]]
  });
}

export function GenerateEvictionDocumentForm(fb: UntypedFormBuilder, intakeForm: IntakeForm): UntypedFormArray {
  const documents = fb.array([]);

  // add dynamic document fields
  if (intakeForm.sections && intakeForm.sections[IntakeFormSections.DOCUMENTS]) {
    const documentSection: IntakeFormSection = intakeForm.sections[IntakeFormSections.DOCUMENTS];
    documentSection.fields.forEach(f => {
      const validators = [FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)];
      if (f.required && (!f.visibility_rules || !f.visibility_rules.length)) { // do not require if visibility rules defined
        validators.push(Validators.required);
      }

      documents.push(new UntypedFormControl(undefined, validators));
    });
  }

  return documents;
}
