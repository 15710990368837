import {CustomFieldType} from '@ee/common/enums';
import {Auditing} from './auditing.model';

export const OperatorList: Operator[] = ['==', '<=', '>=', '>', '<', 'in', 'none', 'blank', 'contains', 'not_contains', 'starts_with', 'ends_with'];
export type LogicalOperator = 'AND' | 'OR';
export type Operator = '==' | '<=' | '>=' | '>' | '<' | 'in' | 'none' | 'blank' | 'contains' | 'not_contains' | 'starts_with' | 'ends_with';
export type ConditionParams = { field: string; opp: Operator; val: string | number | boolean };
export type Conditions = ConditionParams //| LogicalOperator | ConditionsList;// --> no nested rules and inferred AND

export type ConditionsList = Array<Conditions>;

export const fieldOptions: { name: string, description: string, icon: string, type: CustomFieldType }[] = [
  {
    name: 'Calculated',
    description: 'A calculated field based on an equation containing zero or more other fields.',
    icon: 'far fa-calculator',
    type: CustomFieldType.CALCULATED
  },
  {
    name: 'Checkbox',
    description: 'A field used to represent a true or false value.',
    icon: 'far fa-square-check',
    type: CustomFieldType.BOOLEAN
  },
  {
    name: 'Date Input',
    description: 'A date input field.',
    icon: 'far fa-calendar-days',
    type: CustomFieldType.DATE
  },
  {
    name: 'Dollar Amount Input',
    description: 'An input field that allows for entering dollar amounts.',
    icon: 'far fa-square-dollar',
    type: CustomFieldType.DECIMAL
  },
  {
    name: 'Dropdown',
    description: 'Allows the client to select from a list of choices.',
    icon: 'far fa-list-dropdown',
    type: CustomFieldType.SELECT
  },
  {
    name: 'Text Input',
    description: 'A simple text input field.',
    icon: 'far fa-input-pipe',
    type: CustomFieldType.TEXT
  }
];

export function fieldTypeToIcon(type: CustomFieldType): string {
  return fieldOptions.find(o => o.type === type)?.icon;
}

export interface BasicField {
  label: string;
  field?: string | null;
  type: CustomFieldType;
}

export interface CustomField extends BasicField, Auditing  {
  hint?: string;
  hint_more_info?: string;
  order?: number;
  flex?: number;
  required?: boolean;
  value?: string;
  link_id?: string; // generic field to link to any other resources
  options?: string[];
  false_label?: string;
  true_label?: string;
  include_in_total?: boolean;
  prevent_removal?: boolean;
  hide_from_intake?: boolean;
  hide?: boolean;
  hide_from_client?: boolean;
  built_in_field?: boolean;
  visibility_rules?: ConditionsList;
  visible_rule?: string;
  editable_rule?: string;
  required_rule?: string;
  default_rule?: string;
}
