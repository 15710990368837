import {UntypedFormArray, ValidatorFn} from '@angular/forms';
import {PastDueRentHistory} from '@ee/common/models';
import uniqWith from 'lodash-es/uniqWith';

export class UniquePastDueRentHistoryValidator {
  static createValidator() {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const values = formArray.controls
        .map(control => control.value) as PastDueRentHistory[];

      const uniqueRows = uniqWith(values,
        (a, b) => a.month === b.month && a.year === b.year);

      // if duplicate values exist with same month + year, indicate
      return values.length === uniqueRows.length ? null : { uniquePastDueRentHistory: true };
    };

    return validator;
  }
}
