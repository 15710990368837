import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {
  BaseTask,
  BillableItem,
  ClientSettings,
  County,
  CountyFeeSetting,
  CustomField,
  GenerateBillableItemForm,
  Tenant,
  WorkflowStep
} from '@ee/common/models';
import forEach from 'lodash-es/forEach';
import {BillableItemType, CustomFieldType, FlatFeeType} from '@ee/common/enums';
import {generateCourtTaskForm, GenerateCustomFieldForm} from '@ee/common/forms';
import {BillingFormComponent} from '@ee/common/billable-item-editor';
import {DateTime} from 'luxon';

export function GenerateStepCompletedForm(fb: UntypedFormBuilder, nextStep: WorkflowStep, county: County, tenants: Tenant[],
  countyFeeSetting: CountyFeeSetting, clientSettings: ClientSettings | null, nextCourtTask: BaseTask | null,
  forceJudgementFormCompletion: boolean = false) {
  const form = fb.group({
    comment: '',
    notes: '',
    document_templates: [],
    hidden_document_templates: [],
    custom_fields: fb.array([]),
    billable_items: fb.array([])
  });

  // set up custom fields
  if (nextStep?.custom_fields?.length) {
    forEach(nextStep?.custom_fields, (field: CustomField) => {
      if (field.type !== CustomFieldType.SELECT || (field.options && field.options.length > 0)) {
        (form.get('custom_fields') as UntypedFormArray).push(GenerateCustomFieldForm(fb, field));
      }
    });
  }

  // set up auto-billables
  if (nextStep?.billable_items) {
    forEach(nextStep?.billable_items, (b: BillableItem) => {
      const billableForm = generateBillables(fb, b, clientSettings, countyFeeSetting, county, tenants);
      (form.get('billable_items') as UntypedFormArray).push(billableForm);
    });
  }

  // set up court task completion form
  if (nextCourtTask && !nextCourtTask?.completed_date && (nextStep?.show_judgement_result || forceJudgementFormCompletion)) {
    const taskForm = generateCourtTaskForm(fb, nextCourtTask, 'Judgment Result',
      (nextStep?.judgement_required || forceJudgementFormCompletion));
    form.addControl('judgement_result', taskForm);
  }

  // set up court task creation form
  if (nextStep?.show_new_court_date_form && (!form.get('judgement_result') || nextStep?.new_court_date_required)) {
    let taskName = 'Court Appearance';
    if (form.get('judgement_result')) {
      taskName = 'Followup ' + taskName;
    } else {
      taskName = 'First ' + taskName;
    }

    const taskForm = generateCourtTaskForm(fb, null, taskName);
    // default due_date to today at 3pm in luxon DateTime format
    taskForm.get('due_date').setValue(DateTime.local().set({hour: 15, minute: 0}));
    form.addControl('new_court_task', taskForm);
  }

  // set up document templates that should be generated
  form.get('document_templates').setValue(nextStep?.document_templates ?? []);
  form.get('hidden_document_templates').setValue(nextStep?.hidden_document_templates ?? []);

  return form;
}

function generateBillables(fb: UntypedFormBuilder, billableItem: BillableItem, clientSettings: ClientSettings | null, countyFee: CountyFeeSetting, county?: County,
  tenants: Tenant[] = []): UntypedFormGroup {
  let billableForm = GenerateBillableItemForm(fb, billableItem);
  if (billableItem.type === BillableItemType.FLAT_FEE && billableItem.flat_fee_type === FlatFeeType.COUNTY_FEE) {
    billableForm = generateCountyFeeBillable(fb, countyFee, county, tenants);
  } else if (billableItem.type === BillableItemType.FLAT_FEE && billableItem.flat_fee_type === FlatFeeType.CLIENT_ATTORNEY_FEE) {
    billableForm = generateAttorneyFeeBillable(fb, billableItem, clientSettings);
  }
  return billableForm;
}

export function generateCountyFeeBillable(fb: UntypedFormBuilder, countyFee: CountyFeeSetting, county: County, tenants: Tenant[]): UntypedFormGroup {
  const billableForm = GenerateBillableItemForm(fb);
  billableForm.get('type').setValue(BillableItemType.FLAT_FEE);
  billableForm.get('flat_fee_type').setValue(FlatFeeType.COUNTY_FEE);
  if (countyFee) {
    billableForm.get('amount').setValue(BillingFormComponent.calculateCountyFeeAmount(countyFee, tenants));
  } else {
    billableForm.get('amount').setValue(0);
  }
  billableForm.get('description').setValue(FlatFeeType.COUNTY_FEE + (county?.name ? ` - ${county?.name}` : ''));
  return billableForm;
}

export function generateAttorneyFeeBillable(fb: UntypedFormBuilder, billableItem: BillableItem, clientSettings: ClientSettings | null): UntypedFormGroup {
  const billableForm = GenerateBillableItemForm(fb);
  billableForm.get('type').setValue(BillableItemType.FLAT_FEE);
  billableForm.get('flat_fee_type').setValue(FlatFeeType.CLIENT_ATTORNEY_FEE);
  if (clientSettings?.attorney_fee) {
    billableForm.get('amount').setValue(clientSettings.attorney_fee);
    billableForm.get('description').setValue(billableItem.description);
  } else {
    billableForm.get('amount').setValue(0);
    billableForm.get('description').setValue('Attorney Fee');
  }
  return billableForm;
}
