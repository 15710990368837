import { CustomField } from '@ee/common/models';
import { CustomFieldType } from '@ee/common/enums';

export const CUSTOM_FIELDS = [
  {
    order: 0,
    label: 'Docket Number',
    field: 'docketNumber',
    type: CustomFieldType.TEXT,
    required: true,
    built_in_field: true
  } as CustomField,
  { order: 1, label: 'Notice Date', field: 'noticeNumber', type: CustomFieldType.DATE, required: false, built_in_field: true } as CustomField,
  { order: 0, label: 'Serve Date', field: 'serveDate', type: CustomFieldType.DATE, required: true, built_in_field: true } as CustomField,
  {
    order: 1,
    label: 'Served In Person',
    field: 'servedInPerson',
    type: CustomFieldType.BOOLEAN,
    required: true,
    built_in_field: true
  } as CustomField,
  {
    order: 0,
    label: 'Detainer Warrant Number',
    field: 'detainerWarrantNumber',
    type: CustomFieldType.TEXT,
    required: true,
    built_in_field: true
  } as CustomField
] as CustomField[];
