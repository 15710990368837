import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {BulkImportField, IntakeForm} from '@ee/common/models';
import {IntakeFormType} from '@ee/common/enums';

@Injectable({ providedIn: 'root' })
export class IntakeFormService {
  constructor(private http: HttpClient,
              @Inject('environment') private environment) {
  }

  findById(id: string): Observable<IntakeForm> {
    return this.http.get<IntakeForm>(`${this.environment.api_prefix}api/intake-form/${id}`);
  }

  findByType(type: IntakeFormType): Observable<IntakeForm> {
    return this.http.get<IntakeForm>(`${this.environment.api_prefix}api/intake-form/type/${type}`);
  }

  findByTypeAndOrganizationId(type: IntakeFormType, organizationId: string, forceDefault = false): Observable<IntakeForm> {
    let queryOptions = '';
    if (forceDefault) {
      queryOptions = '?force-default=true';
    }
    return this.http.get<IntakeForm>(`${this.environment.api_prefix}api/` +
      `intake-form/type/${type}/organization/${organizationId}${queryOptions}`);
  }

  findBulkImportFieldsByTypeAndOrganizationId(type: IntakeFormType, organizationId: string): Observable<BulkImportField[]> {
    return this.http.get<BulkImportField[]>(`${this.environment.api_prefix}api/` +
      `intake-form/type/${type}/organization/${organizationId}/bulk-import-fields`);
  }

  getReservedFields(): Observable<string[]> {
    return this.http.get<string[]>(`${this.environment.api_prefix}api/intake-form/reserved-fields`);
  }

  save(type: IntakeFormType, value: IntakeForm): Observable<IntakeForm> {
    return this.http.post<IntakeForm>(`${this.environment.api_prefix}api/intake-form/type/${type}`, value);
  }

}
