import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {STATES} from '@ee/common/constants';
import {AddressSuggestion, populateForm} from '@ee/common/models';

@Component({
  selector: 'ee-organization-form',
  template: `
    <div class="flex flex-col" [formGroup]="form">
      <mat-form-field class="flex flex-1">
        <mat-label>Company Name</mat-label>
        <input matInput formControlName="company_name" required>
        <mat-error
          *ngIf="form?.get('company_name').hasError('required')">
          Please enter company name.
        </mat-error>
        <mat-hint>Required</mat-hint>
      </mat-form-field>

      <ee-address-autocomplete placeholder="Street Address" [required]="true"
                               [formControl]="$any(form.get('street_address_1'))"
                               (selectionChange)="onAddressSelected($event)">
      </ee-address-autocomplete>

      <mat-form-field>
        <mat-label>Street Address 2</mat-label>
        <input matInput formControlName="street_address_2">
        <mat-hint>Optional</mat-hint>
      </mat-form-field>
      <div class="flex flex-col md:flex-row">
        <mat-form-field class="flex flex-1 portal-remove-fixed-infix-width mb-3 md:mb-0 md:mr-3">
          <mat-label>City</mat-label>
          <input matInput formControlName="city" required>
          <mat-error *ngIf="form?.get('city').hasError('required')">
            Please enter a city.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="portal-remove-fixed-infix-width flex flex-1 mb-3 md:mb-0 md:mr-3">
          <mat-label>State</mat-label>
          <mat-select formControlName="state" required>
            <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
              {{state.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form?.get('state').hasError('required')">
            Please select a state.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="portal-remove-fixed-infix-width flex-1">
          <mat-label>Zip Code</mat-label>
          <input matInput  mask="00000" formControlName="zip_code" required>
          <mat-error *ngIf="form?.get('zip_code').hasError('required')">
            Please enter a zip code.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
      </div>
      <div class="flex flex-col md:flex-row" *ngIf="!hideBillingFields">
        <mat-form-field class="portal-remove-fixed-infix-width flex-1 mb-3 md:mb-0 md:mr-3">
          <mat-label>Contact Email</mat-label>
          <input matInput formControlName="main_email" required>
          <mat-error *ngIf="form?.get('main_email').hasError('required')">
            Please enter a contact email for the organization.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-1 mb-3 md:mb-0 md:mr-3">
          <mat-label>Contact Phone Number</mat-label>
          <input matInput mask="(000) 000-0000" formControlName="main_phone_number" required>
          <mat-error *ngIf="form?.get('main_phone_number').hasError('required')">
            Please enter a phone number.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="flex-1">
          <mat-label>Fax Number</mat-label>
          <input matInput mask="(000) 000-0000" formControlName="main_fax_number">
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class OrganizationFormComponent implements OnInit {
  @Input()
    form: UntypedFormGroup;
  @Input()
    hideBillingFields: boolean;
  states = STATES;

  constructor() {
  }

  ngOnInit() {
  }

  onAddressSelected(suggestion: AddressSuggestion) {
    populateForm(this.form, suggestion);
  }
}
