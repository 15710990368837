import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompleteWorkflowStepComponent } from './complete-workflow-step.component';
import { MatInputModule } from '@angular/material/input';
import { CustomFieldModule } from '@ee/common/custom-field';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { BillableItemEditorModule } from '@ee/common/billable-item-editor';
import { DocumentTemplateEditorModule } from '@ee/common/document-template-editor';
import { TasksModule } from '@ee/common/tasks';
import { LuxonModule } from 'luxon-angular';
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { CdkDrag, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    CustomFieldModule,
    MatButtonModule,
    ReactiveFormsModule,
    BillableItemEditorModule,
    DocumentTemplateEditorModule,
    TasksModule,
    LuxonModule,
    CdkOverlayOrigin,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder
  ],
  declarations: [CompleteWorkflowStepComponent],
  exports: [CompleteWorkflowStepComponent]
})
export class WorkflowStepModule {}
