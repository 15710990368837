import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '@ee/common/models';

@Injectable({providedIn: 'root'})
export class UserSelectorService {
  public resourceUrl: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.resourceUrl = this.environment.api_prefix + 'api/users';
  }

  getUserSuggestions(): Observable<User[]> {
    return this.http.get<User[]>(`${this.resourceUrl}/suggestions`);
  }
}
