import {NgModule} from '@angular/core';
import {DataLettersPipe} from './data-letters.pipe';
import {EnumFilterByPropPipe} from './enum-filter-by-prop.pipe';
import {PhonePipe} from './phone.pipe';
import {SearchPipe} from './search.pipe';


@NgModule({
  declarations: [
    DataLettersPipe,
    EnumFilterByPropPipe,
    PhonePipe,
    SearchPipe
  ],
  exports: [
    DataLettersPipe,
    EnumFilterByPropPipe,
    PhonePipe,
    SearchPipe
  ]
})
export class EePipesModule {}
