import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LandingPageVisit} from '../models/landing-page-visit.model';

@Injectable()
export class IntakeEvictionService {
  constructor(private http: HttpClient) {
  }

  updatePageLoadVisits(landingPageVisit: LandingPageVisit): Observable<LandingPageVisit> {
    return this.http.put<LandingPageVisit>(`${environment.api_prefix}api/page-visits`, landingPageVisit);
  }

  addLandingPageVisit(attorneyId: string): Observable<any> {
    return this.http.post<LandingPageVisit>(`${environment.api_prefix}api/page-visits/${attorneyId}`, null);
  }

}
