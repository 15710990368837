import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {BillableItemEditorDialogComponent} from './billable-item-editor-dialog.component';
import {BillingFormComponent} from './billing-form.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {WorkflowStepBillablesComponent} from './workflow-step-billables.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatAutocompleteModule
  ],
  declarations: [
    BillableItemEditorDialogComponent,
    BillingFormComponent,
    WorkflowStepBillablesComponent
  ],
  exports: [
    BillableItemEditorDialogComponent,
    BillingFormComponent,
    WorkflowStepBillablesComponent
  ]
})
export class BillableItemEditorModule {
}
