export const STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};

export const STATE_LNG_LAT = {
  Alabama:	{lng: -86.791130, lat: 32.806671},
  Alaska:	{lng: -152.404419, lat: 61.370716},
  Arizona:	{lng: -111.431221, lat: 33.729759},
  Arkansas:	{lng: -92.373123, lat: 34.969704},
  California:	{lng: -119.681564, lat: 36.116203},
  Colorado:	{lng: -105.311104, lat: 39.059811},
  Connecticut:	{lng: -72.755371, lat: 41.597782},
  Delaware:	{lng: -75.507141, lat: 39.318523},
  'District of Columbia':	{lng: -77.026817, lat: 38.897438},
  Florida:	{lng: -81.686783, lat: 27.766279},
  Georgia:	{lng: -83.643074, lat: 33.040619},
  Hawaii:	{lng: -157.498337, lat: 21.094318},
  Idaho:	{lng: -114.478828, lat: 44.240459},
  Illinois:	{lng: -88.986137, lat: 40.349457},
  Indiana:	{lng: -86.258278, lat: 39.849426},
  Iowa:	{lng: -93.210526, lat: 42.011539},
  Kansas:	{lng: -96.726486, lat: 38.526600},
  Kentucky:	{lng: -84.670067, lat: 37.668140},
  Louisiana:	{lng: -91.867805, lat: 31.169546},
  Maine:	{lng: -69.381927, lat: 44.693947},
  Maryland:	{lng: -76.802101, lat: 39.063946},
  Massachusetts:	{lng: -71.530106, lat: 42.230171},
  Michigan:	{lng: -84.536095, lat: 43.326618},
  Minnesota:	{lng: -93.900192, lat: 45.694454},
  Mississippi:	{lng: -89.678696, lat: 32.741646},
  Missouri:	{lng: -92.288368, lat: 38.456085},
  Montana:	{lng: -110.454353, lat: 46.921925},
  Nebraska:	{lng: -98.268082, lat: 41.125370},
  Nevada:	{lng: -117.055374, lat: 38.313515},
  'New Hampshire':	{lng: -71.563896, lat: 43.452492},
  'New Jersey':	{lng: -74.521011, lat: 40.298904},
  'New Mexico':	{lng: -106.248482, lat: 34.840515},
  'New York':	{lng: -74.948051, lat: 42.165726},
  'North Carolina':	{lng: -79.806419, lat: 35.630066},
  'North Dakota':	{lng: -99.784012, lat: 47.528912},
  Ohio:	{lng: -82.764915, lat: 40.388783},
  Oklahoma:	{lng: -96.928917, lat: 35.565342},
  Oregon:	{lng: -122.070938, lat: 44.572021},
  Pennsylvania:	{lng: -77.209755, lat: 40.590752},
  'Rhode Island':	{lng: -71.511780, lat: 41.680893},
  'South Carolina':	{lng: -80.945007, lat: 33.856892},
  'South Dakota':	{lng: -99.438828, lat: 44.299782},
  Tennessee:	{lng: -86.692345, lat: 35.747845},
  Texas:	{lng: -97.563461, lat: 31.054487},
  Utah:	{lng: -111.862434, lat: 40.150032},
  Vermont:	{lng: -72.710686, lat: 44.045876},
  Virginia:	{lng: -78.169968, lat: 37.769337},
  Washington:	{lng: -121.490494, lat: 47.400902},
  'West Virginia':	{lng: -80.954453, lat: 38.491226},
  Wisconsin:	{lng: -89.616508, lat: 44.268543},
  Wyoming:	{lng: -107.302490, lat: 42.755966}
};
