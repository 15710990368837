import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import {BaseTask, User} from '@ee/common/models';
import {AccountType} from '@ee/common/enums';
import {OverlayService} from '@ee/common/services';

@Component({
  selector: 'ee-workflow-task',
  template: `
    <div class="workflow-task" (click)="onShowControls()">
      <div class="inline-block">
        This step {{ task.days_remaining < 0 ? 'was' : 'is' }} due
        <span class="task-due-date ml-1" [ngClass]="{'emphasize': isOverdue}">
          {{task?.due_date | dateTimeFromIso | dateTimeToFormat:'MMM d, yyyy'}}
        </span>
        @if (!!task.days_remaining && task.days_remaining < 8) {
          <span [ngClass]="{'emphasize': isOverdue}">
            <span *ngIf="task.days_remaining > 0">
              ({{task.days_remaining}} day{{task.days_remaining > 1 ? 's' : ''}} left)
            </span>
              <span *ngIf="task.days_remaining === 0">(Due today)</span>
              <span *ngIf="task.days_remaining < 0">
              ({{task.days_remaining * -1}} day{{task.days_remaining < -1 ? 's' : ''}} overdue)
            </span>
          </span>
        }
      </div>
      <div *ngIf="assignedUsers?.length" class="flex flex-row justify-center items-center">
        <div class="mr-1">and is assigned to</div>
          <span *ngFor="let user of assignedUsers" class="small"  [matTooltip]="user.display_name" [matTooltipPosition]="'above'"
                [matTooltipShowDelay]="500" [matTooltipHideDelay]="1000" [attr.data-letters]="user.display_name | dataLetters"
                [ngStyle]="{'--user-selector-color': user.color}"></span>
      </div>
      <div class="edit-overlay" *ngIf="showControls">
        <button mat-icon-button class="ee__button ee__large md-gray-bg mr-4" aria-label="Exit" (click)="onHideControls($event)"
                matTooltip="Hide Options" type="button">
          <mat-icon fontSet="fa" fontIcon="fa-x"></mat-icon>
        </button>

        <ee-workflow-task-info [task]="task" [users]="users" (onSave)="save($event)"></ee-workflow-task-info>

        <button class="ee__button ee__large md-gray-bg mr-4" mat-icon-button type="button" (click)="onDelete.emit(task)"
                matTooltip="Remove Due Date">
          <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';

      .workflow-task {
        position: relative;
        color: #424242;
        cursor: pointer;
        user-select: none;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: var(--c-gray-99);

        &:hover {
          background-color: var(--c-gray-100);
        }

        .edit-overlay {
          display: flex;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(249, 249, 249, .9);
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
        }

        .task-due-date {
          font-weight: 600;
          text-wrap: nowrap;
          align-self: start;
        }
      }

      .emphasize {
        font-weight: 600;
        color: var(--primary-red);
      }

      .save-users-button {
        text-align: center;
        background-color: var(--primary-color);
        color: #fff;

        &:hover {
          color: var(--dark-gray);
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false
})
export class WorkflowTaskComponent implements OnInit, OnChanges {
  public isOverdue = false;
  public showControls = false;
  public assignedUsers: User[] = [];

  @Input({ required: true })
  public task: BaseTask;

  @Input()
  public users: User[] = [];

  @Output()
  public onDelete = new EventEmitter<BaseTask>();

  @Output()
  public onUpdateTask = new EventEmitter<BaseTask>();

  constructor(private eRef: ElementRef, private overlayService: OverlayService, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.isOverdue = this.task.days_remaining && this.task.days_remaining <= 3;
  }

  ngOnChanges() {
    this.assignedUsers = this.users?.filter(user => this.task.assigned_users.includes(user.id))
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    this.changeDetectorRef.markForCheck();
  }

  onShowControls() {
    this.showControls = true;
  }

  onHideControls($event) {
    this.showControls = false;
    $event.stopPropagation();
  }

  save(task: BaseTask) {
    this.onUpdateTask.emit(task);
    this.showControls = false;
  }

  isSelected(user: User): boolean {
    return this.task.assigned_users.includes(user.id);
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(!this.eRef.nativeElement.contains(event.target) && !this.overlayService.isOverlayOpen()) {
      this.showControls = false; // hide controls if clicked outside of component
    }
  }

  protected readonly AccountType = AccountType;
}
