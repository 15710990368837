import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserSelectorComponent} from './user-selector.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserSelectorService} from './user-selector.service';
import {MatTooltip} from '@angular/material/tooltip';
import {EePipesModule} from '@ee/common/pipes';


@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, MatTooltip, EePipesModule],
  declarations: [UserSelectorComponent],
  providers: [UserSelectorService],
  exports: [UserSelectorComponent]
})
export class UserSelectorModule {}
