import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {BytesPipe} from '@ee/common/pipes';

@Component({
  selector: 'ee-upload-selector',
  template: `
    <div class="upload-wrapper">
      <div class="dropzone"
           [ngClass]="{'selected': files.length && !hasError, 'error': hasError}">
        <input class="dz" type="file" #fileInputEl [attr.accept]="accept" [multiple]="false" [disabled]="disable" />
        <mat-icon *ngIf="hasError" class="upload" fontSet="fa" fontIcon="fa-file-xmark"></mat-icon>
        <mat-icon *ngIf="!hasError && !files.length" class="upload" fontSet="fa" fontIcon="fa-file-upload"></mat-icon>
        <mat-icon *ngIf="!hasError && files.length" class="upload" fontSet="fa" fontIcon="fa-file-check"></mat-icon>
        <h3 class="filename">{{files.length && files[0] ? getFileName(files[0]) : 'No file selected'}}</h3>
        <h3 class="pr-4 pl-4">Drag and drop file here or click to</h3>
        <label class="browse-label">Browse for{{files.length ? ' a different ' : ' '}}file</label>
      </div>
    </div>
  `,
  styles: [`
    @import 'components/color-palette';

    .upload-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: stretch;
      align-items: stretch;

      h3 {
        font-size: .9rem;
      }

      .dz {
        display: none;
      }

      .dropzone {
        padding: 1rem 0;
        width: 100%;
        text-align: center;
        border: 4px dashed var(--primary-color);
        position: relative;
        cursor: pointer;

        &.error {
          border-color: var(--primary-red);

          .upload {
            color: var(--primary-red);
          }
        }

        &.selected {
          border-color: var(--primary-green);

          .upload {
            color: var(--primary-green);
          }
        }

        .browse-label {
          line-height: 50px;
          background-color: var(--primary-color);
          color: #fff;
        }

        .upload {
          color: var(--primary-color);
        }

        .mat-icon.upload {
          font-size: 2rem;
          width: 2rem;
          line-height: 2rem;
          margin-bottom: 1rem;
          height: 2rem;
        }

        .filename {
          max-width: 200px;
          margin: 0 auto;
          overflow: hidden;
          white-space: nowrap;
          text-align: center;
          text-overflow: ellipsis;
        }
      }
    }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UploadSelectorComponent),
    multi: true
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class UploadSelectorComponent implements OnDestroy, ControlValueAccessor {
  subs: Subscription[] = [];
  touched = false;
  errorMessages = new Map();

  @Input() hasError = false;

  @Input() required = false;

  @Input() disable = false;

  private _disabled = false;
  private _files: File[] = [];
  private _isDragOver = false;

  constructor(private cdr: ChangeDetectorRef) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _onChange = (val: File[]) => {
  };
  private _onTouched = () => {
    this.touched = true;
  };

  @Input() accept = '*';

  @HostBinding('class.disabled')
  @Input()
  get disabled() {
    return this._disabled;
  }

  set disabled(val: boolean) {
    this._disabled = coerceBooleanProperty(val);
  }

  get files() {
    return this._files;
  }

  @HostBinding('class.empty-input')
  get isEmpty() {
    return !this.files?.length;
  }

  get isDragover() {
    return this._isDragOver;
  }

  set isDragover(value: boolean) {
    if (!this.disabled) {
      this._isDragOver = value;
    }
  }

  @Output() private valueChanged = new EventEmitter<File[]>();
  @ViewChild('fileInputEl') private fileInputEl: ElementRef;
  @Input() emptyPlaceholder = 'Drop file or click to select';

  writeValue(files: File[]): void {
    const fileArray = this.convertToArray(files);
    if (fileArray.length < 2) {
      this._files = fileArray;
      this.emitChanges(this._files);
      this.cdr.detectChanges();
    } else {
      alert('Multiple files not allowed. Please select one file for each type of required document.');
      throw Error('Multiple files not allowed');
    }

  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private emitChanges(files: File[]) {
    this.valueChanged.emit(files);
    this._onChange(files);
  }


  addFiles(files: File[] | FileList | File) {
    this._onTouched();
    const fileArray = this.convertToArray(files);
    this.writeValue(fileArray);
  }

  removeFile(file: File) {
    const fileIndex = this.files.indexOf(file);
    if (fileIndex >= 0) {
      const currentFiles = this.files.slice();
      currentFiles.splice(fileIndex, 1);
      this.writeValue(currentFiles);
    }
  }

  clear() {
    this.writeValue([]);
    this.cdr.detectChanges();
  }

  @HostListener('change', ['$event'])
  change(event: Event) {
    event.stopPropagation();
    this._onTouched();
    const fileList: FileList = (event.target as HTMLInputElement).files;
    if (fileList?.length) {
      this.addFiles(fileList);
    } else {
      this.clear();
    }
    // clear it so change is triggered if same file is selected again
    (event.target as HTMLInputElement).value = '';
  }

  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  activate(e) {
    e.preventDefault();
    this.isDragover = true;
  }

  @HostListener('dragleave', ['$event'])
  deactivate(e) {
    e.preventDefault();
    this.isDragover = false;
  }

  @HostListener('drop', ['$event'])
  handleDrop(e) {
    this.deactivate(e);
    if (!this.disabled) {

      const fileList = e.dataTransfer.files;
      this.removeDirectories(fileList).then((files: File[]) => {
        if (files?.length) {
          this.addFiles(files);
        }
        this._onTouched();
      });
    }
  }

  @HostListener('click')
  open() {
    if (!this.disabled) {
      this.fileInputEl?.nativeElement.click();
    }
  }

  public getFileName(file: File): string {
    const size = BytesPipe.transform(file.size, 2, 'B', 'MB');
    return `${file.name} (${size})`;
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  private convertToArray(files: FileList | File[] | File | null | undefined): File[] {
    if (files) {
      if (files instanceof File) {
        return [files];
      } else if (Array.isArray(files)) {
        return files;
      } else {
        return Array.prototype.slice.call(files);
      }
    }
    return [];
  }

  private removeDirectories(files: FileList) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      const fileArray = this.convertToArray(files);
      const dirnames = [];
      const readerList = [];

      for (let i = 0; i < fileArray.length; i++) {
        const reader = new FileReader();

        reader.onerror = () => {
          dirnames.push(fileArray[i].name);
        };

        reader.onloadend = () => addToReaderList(i);

        reader.readAsArrayBuffer(fileArray[i]);
      }

      function addToReaderList(val: number) {
        readerList.push(val);
        if (readerList.length === fileArray.length) {
          resolve(fileArray.filter((file: File) => !dirnames.includes(file.name)));
        }
      }
    });
  }
}
