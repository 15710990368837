<div class="landing-container etracker auth-container no-bg-img flex flex-col">
  <mat-toolbar class="header mat-elevation-z2" *ngIf="attorney">
    <mat-toolbar-row class="flex flex-row justify-center items-center md:justify-start md:items-center">
      <a [href]="baseUrl"
         class="flex flex-row flex-[1_1_100%] md:flex-[1_1_30%] justify-center items-center md:items-center">
        <img *ngIf="logo" class="attorney-logo" [src]="logo" alt="Home">
        <span class="no-logo" *ngIf="!logo">{{attorney?.company_name}}</span>
      </a>
      <div class="hidden md:visible md:flex flex-row justify-center item-center md:flex-[1_1_40%]">
        <h2 class="main-header">Eviction Intake Form</h2>
      </div>
      <div class="hidden md:visible md:flex md:flex-[1_1_30%]"></div>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="landing flex-1 flex flex-row justify-center items-start" *ngIf="!isSuccess && attorney; else success">
    <div class="flex flex-col flex-[1_0_100%] justify-stretch items-stretch md:w-[1024px]">
      <p class="center mb-4">
        Fill out the form below to submit your eviction. Once submitted, the details will land in our inbox,
        where one of our staff members will review your case.
      </p>
      <div class="mb-4 flex flex-row md:justify-center justify-stretch items-stretch">
        <ee-create-eviction class="mb-4 flex-[1_1_100%] md:flex-none" [forceIntakeForm]="true" firstStepLabel="Contact & Business Information"
                            [attorneyId]="attorney?.id" (evictionCreated)="evictionSaved()"></ee-create-eviction>
      </div>

      <div class="flex flex-row justify-center items-center footer">
        &copy; EasyEviction {{year}}. All rights reserved.
      </div>
    </div>
  </div>
  <ng-template #success>
    <div class="scroll-y flex flex-row flex-1 justify-center items-start"
         *ngIf="isSuccess && attorney; else loading">
      <div class="flex flex-col justify-center items-center flex-[1_1_100%] max-w-[90%] md:max-w-[50%]">
        <h2 class="main-header top mb-4">Your Eviction Has Been Submitted for Review</h2>
        <div class="existing-users-wrapper mb-4">
          Here's what happens next:
        </div>
        <ul class="next-steps">
          <li class="flex flex-row justify-start items-center">
            <mat-icon class="icon" fontSet="fa" fontIcon="fa-file-plus" matListIcon></mat-icon>
            <div class="description flex-1">
              <div class="header">Eviction Submitted</div>
              <div>Thank you! Your personal, company and eviction information was successfully submitted into our
                system.
              </div>
            </div>
          </li>
          <li class="flex flex-row justify-start items-center">
            <mat-icon class="icon" fontSet="fa" fontIcon="fa-mailbox" matListIcon></mat-icon>
            <div class="description flex-1">
              <div class="header">Submission Summary Email</div>
              <div>
                You will receive a confirmation email with the details you just provided. Please reach out to us
                to make any corrections.
              </div>
            </div>
          </li>
          <li class="flex flex-row justify-start items-center">
            <mat-icon class="icon" fontSet="fa" fontIcon="fa-file-check" matListIcon></mat-icon>
            <div class="description flex-1">
              <div class="header">Review</div>
              <div>One of our staff members will review your filing.</div>
            </div>
          </li>
          <li class="flex flex-row justify-start items-center">
            <mat-icon class="icon" fontSet="fa" fontIcon="fa-map-marker-alt" matListIcon></mat-icon>
            <div class="description">
              <div class="header">Tracking Number</div>
              <div>
                Once reviewed, you will receive a confirmation email with a tracking number to follow the status of your eviction.
                Please feel free to&nbsp;
                <a href="mailto:{{attorney.main_email}}?subject=Request: New EasyEviction Client Account"
                   target="_blank">email</a>
                &nbsp;us should we not get back to you within 2 days of your submission.
                <br>
                Want to save time going forward? Look for a link in the email to register for a <b>free</b> account to our Portal.
              </div>
            </div>
          </li>
          <li class="flex flex-row justify-start items-center">
            <mat-icon class="icon" fontSet="fa" fontIcon="fa-map-marked-alt" matListIcon></mat-icon>
            <div class="description flex-1">
              <div class="header">Updates</div>
              <div>
                We'll update the status of your eviction every step of the way.
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer flex flex-row justify-center items-center">
      &copy; Triple Play Solutions {{year}}. All rights reserved.
    </div>
  </ng-template>
  <ng-template #loading>
    <div class="flex flex-row flex-1 justify-center items-center">
      <ee-loading *ngIf="isLoading; else invalidAttorney"></ee-loading>
    </div>
  </ng-template>
  <ng-template #invalidAttorney>
    <div *ngIf="!attorney">
      <h2>Attorney not found.</h2>
    </div>
  </ng-template>
</div>
