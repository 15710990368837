import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {GeneratePastDueHistoryForm} from '@ee/common/forms';
import {MONTHS} from '@ee/common/constants';
import {Subscription} from 'rxjs';
import {UniquePastDueRentHistoryValidator} from '@ee/common/validators';

@Component({
  selector: 'ee-past-due-rent-history',
  template: `
    <div class="section-wrapper small-gap" [formGroup]="form">
      <p>Please enter all of the months for which rent is owed.</p>
      <div class="in-row-grid"
           *ngFor="let p of pastDueRentHistory.controls; let i = index"
           formArrayName="past_due_rent_history">
        <div class="quad-column past-due-rent flex flex-row">
          <div class="triple-grid flex-1">
            <mat-form-field>
              <mat-label>Month</mat-label>
              <mat-select [formControl]="$any(p.get('month'))" required>
                <mat-option *ngFor="let month of months; let i = index" [value]="i">
                  {{month}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="p.get('month').hasError('required')">
                Please select a month.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Year</mat-label>
              <mat-select [formControl]="$any(p.get('year'))" required>
                <mat-option *ngFor="let year of years" [value]="year">
                  {{year}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="p.get('year').hasError('required')">
                Please select a year.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
            <mat-form-field>
              <span matPrefix>$ &nbsp;</span>
              <mat-label>Amount</mat-label>
              <input matInput type="number" [formControl]="$any(p.get('amount'))" required autocomplete="off"/>
              <mat-error *ngIf="p.get('amount').hasError('required')">Please enter an amount greater than zero.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
          </div>
          <div class="flex align-center items-center ml-4">
            <button class="quad-column ee__button gray-bg black" type="button" mat-icon-button aria-label="Remove"
                    [disabled]="i === 0" (click)="remove(i)">
              <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="error align-center quad-column" style=""
           *ngIf="pastDueRentHistory.hasError('uniquePastDueRentHistory')">
        Please select unique months.
      </div>
      <button class="quad-column" mat-flat-button type="button" (click)="add()"
              [disabled]="pastDueRentHistory.invalid" color="primary">
        Add Another
      </button>
    </div>
  `,
  styles: [`
    @import '../section-styles';

    .error {
      color: red;
      font-weight: 500;
    }
    .past-due-rent {
      border: 1px solid #ccc;
      padding: 0.75rem;
      border-radius: 8px;
      overflow: hidden;
    }
  `],
  standalone: false
})
export class PastDueRentHistoryComponent implements OnInit, OnDestroy {
  private readonly currentYear = new Date().getFullYear();
  public readonly months = MONTHS;
  public years = [];
  private subs: Subscription[] = [];

  @Input() form: UntypedFormGroup;

  @Output() private valueChanged = new EventEmitter<number>();

  constructor(private fb: UntypedFormBuilder) {
    for (let i = 0; i < 5; i++) {
      this.years.push(this.currentYear - i);
    }
  }

  ngOnInit() {
    this.pastDueRentHistory.addValidators(UniquePastDueRentHistoryValidator.createValidator());
    if (!this.pastDueRentHistory.length) {
      this.add();
    }

    this.subs.push(this.form.get('past_due_rent_history').valueChanges.subscribe(() => {
      let total = 0;
      this.pastDueRentHistory.controls.forEach((c) => {
        total += c.get('amount').value ?? 0;
      });
      this.valueChanged.emit(total);
    }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  add() {
    const form = GeneratePastDueHistoryForm(this.fb);
    this.pastDueRentHistory.push(form, {emitEvent: false});
  }

  remove(i: number) {
    if (i > 0) {
      this.pastDueRentHistory.removeAt(i);
    }
  }

  get pastDueRentHistory(): UntypedFormArray {
    return this.form.get('past_due_rent_history') as UntypedFormArray;
  }
}
