import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormArray, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IntakeFormSection, IntakeOption} from '@ee/common/models';
import {GenerateTenantForm} from '@ee/common/forms';
import {IntakeFormFields} from '@ee/common/enums';
import {EVICTION_REASONS_CLAIMS_MAP} from '@ee/common/constants';

@Component({
  selector: 'ee-eviction-tenants-section',
  template: `
      <div class="tenants-form section-wrapper" [ngClass]="{'demo-mode': demoMode}"
           [formGroup]="form">
          <div class="in-row-grid">
              <mat-accordion class="tenants-wrapper quad-column" *ngIf="tenants?.length; else noTenants">
                  <div class="tenants" *ngFor="let t of tenants.controls; let i = index">
                      <mat-expansion-panel [ngClass]="{'demo-mode': demoMode}"
                                           [expanded]="i === (tenants.controls.length - 1)" [disabled]="demoMode">
                          <mat-expansion-panel-header>
                              <mat-panel-title class="tenant-name">
                                <span *ngIf="t.get('first_name')?.value && t.get('last_name')?.value; else emptyName">
                                  {{t.get('first_name')?.value}} {{t.get('last_name')?.value}}
                                </span>
                                <ng-template #emptyName>
                                    No name specified
                                </ng-template>
                                <button class="ee__button gray-bg black remove" type="button" mat-icon-button aria-label="Remove"
                                        (click)="removeTenant(i)" *ngIf="i > 0" matTooltip="Remove tenant">
                                  <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
                                </button>
                              </mat-panel-title>
                          </mat-expansion-panel-header>
                          <div class="in-row-grid">
                              <mat-form-field class="double-column">
                                <mat-label>Tenant's First Name</mat-label>
                                  <input matInput [formControl]="$any(t.get('first_name'))" required
                                         autocomplete="off"/>
                                  <mat-error *ngIf="t.get('first_name').hasError('required')">Please enter tenant's
                                      first name.
                                  </mat-error>
                                  <mat-hint>Required</mat-hint>
                              </mat-form-field>
                              <mat-form-field *ngIf="options[intakeFields.MIDDLE_NAME]?.show">
                                <mat-label>Tenant's Middle Name</mat-label>
                                  <input matInput [formControl]="$any(t.get('middle_name'))" autocomplete="off"/>
                                  <mat-hint>Optional</mat-hint>
                              </mat-form-field>
                              <mat-form-field class="double-column">
                                <mat-label>Tenant's Last Name</mat-label>
                                  <input matInput [formControl]="$any(t.get('last_name'))" required
                                         autocomplete="off"/>
                                  <mat-error *ngIf="t.get('last_name').hasError('required')">Please enter tenant's
                                      last name.
                                  </mat-error>
                                  <mat-hint>Required</mat-hint>
                              </mat-form-field>
                              <mat-form-field *ngIf="options[intakeFields.PHONE_NUMBER].show">
                                <mat-label>Phone Number</mat-label>
                                  <input matInput mask="(000) 000-0000" autocomplete="off"
                                         [formControl]="$any(t.get('phone_number'))"
                                         [required]="options[intakeFields.PHONE_NUMBER].required">
                                  <mat-error *ngIf="t.get('phone_number').hasError('required')">
                                      Please enter tenant's phone.
                                  </mat-error>
                                  <mat-hint>{{ options[intakeFields.PHONE_NUMBER].required ? 'Required' : 'Optional' }}</mat-hint>
                              </mat-form-field>
                              <mat-form-field *ngIf="options[intakeFields.SSN].show">
                                <mat-label>SSN</mat-label>
                                  <input matInput mask="000-00-0000" [formControl]="$any(t.get('ssn'))"
                                         [required]="options[intakeFields.SSN].required" autocomplete="off">
                                  <mat-error *ngIf="t.get('ssn').hasError('required')">Please enter tenant's social
                                      security number.
                                  </mat-error>
                                  <mat-hint>{{ options[intakeFields.SSN].required ? 'Required' : 'Optional' }}</mat-hint>
                              </mat-form-field>
                              <mat-form-field *ngIf="options[intakeFields.EMAIL]?.show">
                                  <mat-label>Email Address</mat-label>
                                  <input matInput [formControl]="$any(t.get('email'))"
                                         [required]="options[intakeFields.EMAIL].required">
                                  <mat-error *ngIf="t.get('email').hasError('required')">Please enter tenant's email
                                      address.
                                  </mat-error>
                                  <mat-hint>{{ options[intakeFields.EMAIL]?.required ? 'Required' : 'Optional' }}</mat-hint>
                              </mat-form-field>
                              <mat-form-field *ngIf="options[intakeFields.DOB]?.show">
                                <mat-label>Date of Birth</mat-label>
                                <input matInput [formControl]="$any(t.get('dob'))" [matDatepicker]="dbDob"
                                       [required]="options[intakeFields.DOB].required" autocomplete="off">
                                <mat-error *ngIf="t.get('dob').hasError('required')">Please enter tenant's date of
                                    birth.
                                </mat-error>
                                <mat-hint>{{ options[intakeFields.DOB]?.required ? 'Required' : 'Optional' }}</mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="dbDob"></mat-datepicker-toggle>
                                <mat-datepicker #dbDob></mat-datepicker>
                              </mat-form-field>
                              <div class="double-column" *ngIf="options[intakeFields.IS_NOT_ACTIVE_MILITARY_MEMBER]?.show">
                                  <div class="flex flex-row justify-start items-center">
                                      <mat-checkbox class="mr-1" [formControl]="$any(t.get('is_not_active_military_member'))"
                                                    [required]="options[intakeFields.IS_NOT_ACTIVE_MILITARY_MEMBER]?.required">
                                          Tenant is not an active military member.
                                      </mat-checkbox>
                                      <a href="https://scra.dmdc.osd.mil/scra/" target="_blank" class="pt-2">
                                          <mat-icon fontSet="fa" fontIcon="fa-info-circle"
                                                    matTooltip="Click to see more information about SCRA laws."></mat-icon>
                                      </a>
                                  </div>
                                  <mat-error
                                          *ngIf="t.get('is_not_active_military_member').hasError('requiredTrue')">
                                      Tenant cannot be an active member of the military to proceed.
                                  </mat-error>
                              </div>
                          </div>
                      </mat-expansion-panel>
                  </div>
              </mat-accordion>
              <ng-template #noTenants>
                  <div class="invalid">Please add one or more tenants to continue.</div>
              </ng-template>
              <button class="quad-column" mat-flat-button type="button" color="primary"
                      *ngIf="!demoMode" [disabled]="form.invalid" (click)="addTenant()">
                  Add Another Tenant
              </button>
          </div>

          <hr class="mb-4 mt-4">

          <div class="in-row-grid">
              <div class="double-column" *ngIf="options[intakeFields.NOT_ACTIVE_MILITARY]?.show">
                  <div class="flex flex-row justify-start items-center">
                      <mat-checkbox class="mr-1" formControlName="not_active_military"
                                    [required]="options[intakeFields.NOT_ACTIVE_MILITARY]?.required">
                          Tenant(s) are not active military members.
                      </mat-checkbox>
                      <a href="https://scra.dmdc.osd.mil/scra/" target="_blank">
                          <mat-icon fontSet="fa" fontIcon="fa-info-circle"
                                    matTooltip="Click to see more information about SCRA laws."></mat-icon>
                      </a>
                  </div>
                  <mat-error *ngIf="form.get('not_active_military').hasError('required')">
                      Tenant(s) cannot be active member of the military to proceed.
                  </mat-error>
              </div>

              <div class="double-column"
                   *ngIf="options[intakeFields.APPLIED_FOR_ASSISTANCE] && options[intakeFields.APPLIED_FOR_ASSISTANCE].show">
                  <div class="flex flex-row justify-start items-center">
                      <mat-checkbox class="mr-1" formControlName="applied_for_assistance">
                          {{!!options[intakeFields.APPLIED_FOR_ASSISTANCE]['label_override'] ?
                              options[intakeFields.APPLIED_FOR_ASSISTANCE]['label_override'] :
                              'Tenant(s) has applied for government assistance.'}}
                      </mat-checkbox>
                      <a *ngIf="options[intakeFields.APPLIED_FOR_ASSISTANCE]['more_info_link']"
                         [href]="options[intakeFields.APPLIED_FOR_ASSISTANCE]['more_info_link']" target="_blank">
                          <mat-icon fontSet="fa" fontIcon="fa-info-circle"
                                    matTooltip="Click to see more information."></mat-icon>
                      </a>
                  </div>
                  <mat-error *ngIf="form.get('applied_for_assistance').hasError('required')">
                      Tenant(s) must have not already filed for government assistance to proceed.
                  </mat-error>
              </div>
          </div>

          <div class="in-row-grid">
              <mat-form-field *ngIf="options[intakeFields.EVICTION_REASON].show">
                <mat-label>Eviction Reason</mat-label>
                <mat-select formControlName="eviction_reason"
                            [required]="options[intakeFields.EVICTION_REASON].required">
                    <mat-option *ngFor="let reason of evictionReasons" [value]="reason">
                        {{reason}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form?.get('eviction_reason').hasError('required')">
                    Please select a reason.
                </mat-error>
                <mat-hint>
                    {{ options[intakeFields.EVICTION_REASON].required ? 'Required' : 'Optional' }}
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="triple-column" *ngIf="options[intakeFields.REASON].show">
                <mat-label>Other Reason Description</mat-label>
                <input matInput formControlName="reason"
                       [required]="options[intakeFields.REASON].required">
                <mat-error *ngIf="form.get('reason').hasError('required')">
                    An eviction reason is required.
                </mat-error>
                <mat-hint>
                    {{ options[intakeFields.REASON].required ? 'Required' : 'Optional' }}
                </mat-hint>
              </mat-form-field>
          </div>

          <div class="in-row-grid" formGroupName="custom_fields" *ngIf="tenantSection?.fields?.length && tenantFields">
              <ee-field *ngFor="let f of tenantSection?.fields; let i = index"
                        [ngClass]="{'margin-bottom-sm': i < (tenantSection.fields.length - 1),
                                    'md:mb-4': i < (tenantSection.fields.length - 1),
                                    'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                        [hideFromIntake]="f.hide_from_intake"
                        [hidden]="f.hide_from_intake && !overrideFieldVisibility"
                        [control]="$any(tenantFields.controls[f.field])" [field]="f">
              </ee-field>
          </div>
      </div>
  `,
  styles: [`
    @import '../section-styles';

    .tenant-name {
      font-size: 1.1rem;
      justify-content: space-between;
      align-items: center;
    }

    .tenants-form {
      &.demo-mode {
        .tenants { padding-right: 0; }
      }
    }
    .invalid {
      color: red;
    }
    mat-mdc-accordion.tenants-wrapper {
      .mat-mdc-expansion-panel-header-title {
        font-size: 1rem;
        font-weight: 500;
      }
    }
  `],
  standalone: false
})
export class EvictionTenantsSectionComponent implements OnInit {
  @Input() public form: UntypedFormGroup;

  @Input() public options: Map<string, IntakeOption> | undefined;

  @Input() public demoMode = false;

  @Input() overrideFieldVisibility = false;

  @Input() tenantSection: IntakeFormSection | undefined;

  intakeFields = IntakeFormFields;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    if (!this.tenants.length) {
      this.addTenant();
    }
  }

  public get evictionReasons(): string[] {
    let options = Array.from(EVICTION_REASONS_CLAIMS_MAP.keys());
    if (this.options && this.options[this.intakeFields.EVICTION_REASON]?.options?.length) {
      options = this.options[this.intakeFields.EVICTION_REASON].options;
    }
    return options;
  }

  get tenantFields(): UntypedFormGroup | undefined {
    return this.form.get('custom_fields') as UntypedFormGroup;
  }

  addTenant() {
    const newTenant = GenerateTenantForm(this.fb, this.options, this.demoMode);
    if (this.demoMode) {
      newTenant.get('first_name').setValue('Demo');
      newTenant.get('last_name').setValue('Tenant');
    }
    this.tenants.push(newTenant, {emitEvent: false});
  }

  removeTenant(i: number) {
    this.tenants.removeAt(i);
  }

  get tenants(): UntypedFormArray {
    return this.form.get('tenants') as UntypedFormArray;
  }
}
