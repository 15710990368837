import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatStepperModule } from '@angular/material/stepper';
import { CAPTCHA_CONFIG, NgHcaptchaModule } from 'ng-hcaptcha';
import { EvictionTenantsSectionComponent } from './forms/eviction-tenants-section.component';
import { EvictionPropertyDetailsComponent } from './forms/eviction-property-details.component';
import { EvictionPropertySectionComponent } from './forms/eviction-property-section.component';
import { ClaimsSectionComponent } from './forms/claims-section.component';
import { LeaseTermsSectionComponent } from './forms/lease-terms-section.component';
import { InternalSectionComponent } from './forms/internal-section.component';
import { OrganizationFormComponent } from './forms/organization-form.component';
import { NewClientFormComponent } from './forms/new-client-form.component';
import { CreateEvictionComponent } from './create-eviction/create-eviction.component';
import { WorkflowFormComponent } from './forms/workflow-form.component';
import { EvictionDocumentationComponent } from './forms/eviction-documentation.component';
import { MatLineModule, MatNativeDateModule } from '@angular/material/core';
import { EePipesModule } from '@ee/common/pipes';
import { EeDirectivesModule } from '@ee/common/directives';
import { LoadingModule } from '@ee/common/loading';
import { AddressAutocompleteModule } from '@ee/common/address-autocomplete';
import { ClientSelectionModule } from '@ee/common/client-selection';
import { MatRadioModule } from '@angular/material/radio';
import { CustomFieldModule } from '@ee/common/custom-field';
import { MatListModule } from '@angular/material/list';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { PastDueRentHistoryComponent } from './forms/past-due-rent-history.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { LayoutModule } from '@angular/cdk/layout';
import { NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { BillableItemEditorModule } from '@ee/common/billable-item-editor';
import { DocumentTemplateEditorModule } from '@ee/common/document-template-editor';
import { ExpansionPanelModule } from '@ee/common/expansion-panel';
import { UploaderModule } from '@ee/common/uploader';
import { WorkflowStepModule } from '@ee/common/workflow-step';
import { IntakeEvictionFormService } from './services/intake-eviction-form.service';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSnackBarModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatStepperModule,
    NgHcaptchaModule,
    EePipesModule,
    EeDirectivesModule,
    LoadingModule,
    AddressAutocompleteModule,
    ClientSelectionModule,
    MatRadioModule,
    CustomFieldModule,
    MatListModule,
    TooltipModule,
    MatExpansionModule,
    LayoutModule,
    BillableItemEditorModule,
    NgxMaskDirective,
    DocumentTemplateEditorModule,
    ExpansionPanelModule,
    MatLineModule,
    UploaderModule,
    WorkflowStepModule
  ],
  declarations: [
    EvictionDocumentationComponent,
    EvictionPropertyDetailsComponent,
    EvictionTenantsSectionComponent,
    PastDueRentHistoryComponent,
    OrganizationFormComponent,
    NewClientFormComponent,
    CreateEvictionComponent,
    WorkflowFormComponent,
    EvictionPropertySectionComponent,
    LeaseTermsSectionComponent,
    ClaimsSectionComponent,
    InternalSectionComponent
  ],
  exports: [
    CreateEvictionComponent,
    PastDueRentHistoryComponent,
    EvictionDocumentationComponent,
    EvictionPropertyDetailsComponent,
    EvictionTenantsSectionComponent,
    OrganizationFormComponent,
    NewClientFormComponent,
    WorkflowFormComponent,
    EvictionPropertySectionComponent,
    LeaseTermsSectionComponent,
    ClaimsSectionComponent,
    InternalSectionComponent
  ],
  providers: [IntakeEvictionFormService, provideEnvironmentNgxMask()]
})
export class EvictionIntakeModule {
  public static forRoot(environment: any): ModuleWithProviders<EvictionIntakeModule> {
    return {
      ngModule: EvictionIntakeModule,
      providers: [
        { provide: 'environment', useValue: environment },
        { provide: CAPTCHA_CONFIG, useValue: { siteKey: environment.hcaptcha_sitekey, languageCode: 'en' } }
      ]
    };
  }
}
