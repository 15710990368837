import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {DocumentSelectorComponent} from './document-selector.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  imports: [CommonModule, NgSelectModule, ReactiveFormsModule, FormsModule],
  exports: [DocumentSelectorComponent],
  declarations: [DocumentSelectorComponent]
})
export class DocumentTemplateEditorModule {}
