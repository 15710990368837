import { Injectable } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  constructor(private overlayContainer: OverlayContainer) {}

  isOverlayOpen(): boolean {
    const overlayContainerElement = this.overlayContainer.getContainerElement();
    return overlayContainerElement.childElementCount > 0;
  }
}
