import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClientSelectComponent} from './client-select.component';
import {ClientAutocompleteComponent} from './client-autocomplete.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {EePipesModule} from '@ee/common/pipes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatAutocompleteModule,
    EePipesModule
  ],
  declarations: [
    ClientSelectComponent,
    ClientAutocompleteComponent
  ],
  exports: [
    ClientSelectComponent,
    ClientAutocompleteComponent
  ]
})
export class ClientSelectionModule {
  public static forRoot(environment: any): ModuleWithProviders<ClientSelectionModule> {
    return {
      ngModule: ClientSelectionModule,
      providers: [
        {provide: 'environment', useValue: environment}
      ]
    };
  }
}
