import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AddressAutocompleteComponent} from './address-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule
  ],
  declarations: [
    AddressAutocompleteComponent
  ],
  exports: [
    AddressAutocompleteComponent
  ]
})
export class AddressAutocompleteModule {
  public static forRoot(environment: any): ModuleWithProviders<AddressAutocompleteModule> {
    return {
      ngModule: AddressAutocompleteModule,
      providers: [
        {provide: 'environment', useValue: environment}
      ]
    };
  }
}
