import {STATES} from '@ee/common/constants';
import {UntypedFormGroup} from '@angular/forms';
import findKey from 'lodash-es/findKey';

export interface AddressSuggestion {
  display: string;
  street_address: string;
  city: string;
  state_full: string;
  zip_code: string;
  lat: number;
  lng: number;
}

export function populateForm(form: UntypedFormGroup, suggestion: AddressSuggestion, fieldPrefix = '') {
  const state = findKey(STATES, (s) => s === suggestion.state_full);
  form.get(fieldPrefix + 'street_address_1').setValue(suggestion.street_address);
  form.get(fieldPrefix + 'city').setValue(suggestion.city);
  form.get(fieldPrefix + 'state').setValue(state);
  form.get(fieldPrefix + 'zip_code').setValue(suggestion.zip_code);

  if (form.get(fieldPrefix + 'lat') && form.get(fieldPrefix + 'lng')) {
    form.get(fieldPrefix + 'lat').setValue(suggestion.lat);
    form.get(fieldPrefix + 'lng').setValue(suggestion.lng);
  }
}
