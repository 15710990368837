export const AUTHORIZATION_BEARER_TOKEN_PREFIX = 'Bearer ';
export const USER_LIVE_CHANNEL = '/user/queue/specific-user-user';
export const MAX_FILE_UPLOAD_SIZE = 20971520;
export const PASSWORD_STRENGTH_REGEX = '^.*(?=.{8,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).*$';
export const PDF_FILE_EXTENSION = '.pdf';
export const DOCX_FILE_EXTENSION = '.docx';
export const PDF_FILE_MIME_TYPES = ['application/pdf'];
export const DOCX_FILE_MIME_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12'
];
export const SUPPORTED_PREVIEW_MIME_TYPE = PDF_FILE_MIME_TYPES.concat(DOCX_FILE_MIME_TYPES);
export const EVICTION_REASONS_CLAIMS_MAP = new Map<string, boolean>([
  ['Unpaid Rent', true],
  ['Criminal Willful Conduct', true],
  ['Holdover Tenancy', true],
  ['Material Non-Compliance', true],
  ['Foreclosure', true],
  ['Other', true]
]);
