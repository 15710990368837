import {
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {IntakeFormSection, IntakeOption} from '@ee/common/models';
import {IntakeFormFields} from '@ee/common/enums';

@Component({
  selector: 'ee-lease-terms-section',
  template: `
      <div class="section-wrapper" [formGroup]="form">
          <div class="in-row-grid">
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.LEASE_RENT_AMOUNT])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.LEASE_RENT_AMOUNT]?.hide_from_intake}">
                <mat-label>Lease Monthly Rent</mat-label>
                <span matPrefix>$ &nbsp;</span>
                <input matInput type="number" formControlName="lease_rent_amount"
                       [required]="options[intakeFields.LEASE_RENT_AMOUNT].required">
                <mat-error *ngIf="form?.get('lease_rent_amount').hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-error *ngIf="form?.get('lease_rent_amount').hasError('pattern')">
                    Invalid currency format.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.LEASE_RENT_AMOUNT].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.LEASE_RENT_AMOUNT].hint">
                  . Hover for
                    <span class="field-instructions" [tooltip]="options[intakeFields.LEASE_RENT_AMOUNT].hint"
                          placement="bottom">
                      more info
                    </span>.
                  </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.LEASE_RENT_DUE_DAY])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.LEASE_RENT_DUE_DAY]?.hide_from_intake}">
                <mat-label>Due Day</mat-label>
                <input matInput type="text" formControlName="lease_rent_due_day"
                       [required]="options[intakeFields.LEASE_RENT_DUE_DAY].required">
                <mat-error *ngIf="form?.get('lease_rent_due_day').hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.LEASE_RENT_DUE_DAY].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.LEASE_RENT_DUE_DAY].hint">
                    . Hover for
                      <span class="field-instructions" [tooltip]="options[intakeFields.LEASE_RENT_DUE_DAY].hint"
                            placement="bottom">
                        more info
                      </span>.
                    </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.LEASE_RENT_FREQUENCY])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.LEASE_RENT_FREQUENCY]?.hide_from_intake}">
                <mat-label>Rent Frequency</mat-label>
                <input matInput type="text" formControlName="lease_rent_frequency"
                       [required]="options[intakeFields.LEASE_RENT_FREQUENCY].required">
                <mat-error *ngIf="form?.get('lease_rent_frequency').hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.LEASE_RENT_FREQUENCY].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.LEASE_RENT_FREQUENCY].hint">
                      . Hover for
                        <span class="field-instructions" [tooltip]="options[intakeFields.LEASE_RENT_FREQUENCY].hint"
                              placement="bottom">
                          more info
                        </span>.
                      </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.SECURITY_DEPOSIT])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.SECURITY_DEPOSIT]?.hide_from_intake}">
                <mat-label>Security Deposit</mat-label>
                <span matPrefix>$ &nbsp;</span>
                <input matInput type="number" formControlName="security_deposit"
                       [required]="options[intakeFields.SECURITY_DEPOSIT].required">
                <mat-error *ngIf="form?.get('security_deposit').hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-error *ngIf="form?.get('security_deposit').hasError('pattern')">
                    Invalid currency format.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.SECURITY_DEPOSIT].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.SECURITY_DEPOSIT].hint">
                        . Hover for
                          <span class="field-instructions" [tooltip]="options[intakeFields.SECURITY_DEPOSIT].hint"
                                placement="bottom">
                            more info
                          </span>.
                        </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.LEASE_TERMS])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.LEASE_TERMS]?.hide_from_intake}">
                <mat-label>Lease Terms</mat-label>
                <mat-select formControlName="lease_terms" [required]="options[intakeFields.LEASE_TERMS].required">
                    <mat-option *ngFor="let term of leaseTerms" [value]="term">
                        {{term}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="form?.get('lease_terms')?.hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.LEASE_TERMS].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.LEASE_TERMS].hint">
                          . Hover for
                            <span class="field-instructions" [tooltip]="options[intakeFields.LEASE_TERMS].hint"
                                  placement="bottom">
                              more info
                            </span>.
                          </span>
                </mat-hint>
              </mat-form-field>
              <mat-form-field class="flex-1 md:flex-none" *ngIf="showField(options[intakeFields.MOVE_IN_DATE])"
                              [ngClass]="{'hidden-from-intake': !!options[intakeFields.MOVE_IN_DATE]?.hide_from_intake}">
                <mat-label>Move-In Date</mat-label>
                <input matInput [matDatepicker]="moveInDate" formControlName="move_in_date"
                       [required]="options[intakeFields.MOVE_IN_DATE].required">
                <mat-datepicker-toggle matSuffix [for]="moveInDate"></mat-datepicker-toggle>
                <mat-datepicker #moveInDate></mat-datepicker>
                <mat-error *ngIf="form?.get('move_in_date').hasError('required')">
                    Please enter a value.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.MOVE_IN_DATE].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.MOVE_IN_DATE].hint">
                            . Hover for
                              <span class="field-instructions" [tooltip]="options[intakeFields.MOVE_IN_DATE].hint"
                                    placement="bottom">
                                more info
                              </span>.
                            </span>
                </mat-hint>
              </mat-form-field>
          </div>

          <div class="in-row-grid"
               *ngIf="showField(options[intakeFields.WAIVER_OF_NOTICE]) || showField(options[intakeFields.NOTICE_DATE])"
               [ngClass]="{'hidden-from-intake': !!options[intakeFields.NOTICE_DATE]?.hide_from_intake}">
              <div *ngIf="showField(options[intakeFields.WAIVER_OF_NOTICE])">
                  <mat-checkbox formControlName="waiver_of_notice">
                      Lease contains a waiver of notice
                  </mat-checkbox>
              </div>
              <mat-form-field
                      *ngIf="options[intakeFields.NOTICE_DATE]?.show && ((!form.get(intakeFields.WAIVER_OF_NOTICE)?.value &&
                      form.get(intakeFields.NOTICE_DATE)) || overrideFieldVisibility)">
                <mat-label>Notice Date</mat-label>
                <input matInput [matDatepicker]="noticedp" formControlName="notice_date" [required]="options[intakeFields.NOTICE_DATE].required">
                <mat-datepicker-toggle matSuffix [for]="noticedp"></mat-datepicker-toggle>
                <mat-datepicker #noticedp></mat-datepicker>
                <mat-error *ngIf="form.get(intakeFields.NOTICE_DATE).hasError('required')">
                    Please select a notice date.
                </mat-error>
                <mat-hint>
                  {{ options[intakeFields.NOTICE_DATE].required ? 'Required' : 'Optional' }}
                  <span class="field-instructions-wrapper" *ngIf="options[intakeFields.NOTICE_DATE].hint">
                              . Hover for
                                <span class="field-instructions" [tooltip]="options[intakeFields.NOTICE_DATE].hint"
                                      placement="bottom">
                                  more info
                                </span>.
                              </span>
                </mat-hint>
              </mat-form-field>
          </div>
          <div class="in-row-grid" *ngIf="leaseTermsSection?.fields?.length && leaseTermsFields">
              <ee-field *ngFor="let f of leaseTermsSection?.fields; let i = index"
                        [ngClass]="{'margin-bottom-sm': i < (leaseTermsSection.fields.length - 1),
                                    'md:mb-4': i < (leaseTermsSection.fields.length - 1),
                                    'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                         [hideFromIntake]="f.hide_from_intake"
                         [hidden]="f.hide_from_intake && !overrideFieldVisibility"
                        [control]="$any(leaseTermsFields.controls[f.field])" [field]="f">
              </ee-field>
          </div>
      </div>
  `,
  styles: [`
    @import '../section-styles';
  `],
  standalone: false
})
export class LeaseTermsSectionComponent implements OnDestroy {
  @Input() demoMode = false;

  @Input() overrideFieldVisibility = false;

  @Input() form: UntypedFormGroup;

  @Input() options: Map<string, IntakeOption> | undefined;

  @Input() leaseTermsSection: IntakeFormSection | undefined;

  intakeFields = IntakeFormFields;

  year = new Date().getFullYear();

  subs: Subscription[] = [];


  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }

  get leaseTermsFields(): UntypedFormGroup | undefined {
    return this.form.get('custom_fields') as UntypedFormGroup;
  }

  showField(option: IntakeOption): boolean {
    return option?.show && (!option.hide_from_intake || this.demoMode || this.overrideFieldVisibility);
  }

  public get leaseTerms(): string[] {
    let options = ['Month to Month', 'Written Lease'];


    if (this.options && this.options[this.intakeFields.LEASE_TERMS]?.options?.length) {
      options = this.options[this.intakeFields.LEASE_TERMS]?.options;
    }

    return options;
  }
}
