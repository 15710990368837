export enum IntakeFormFields {
  APPLIED_FOR_ASSISTANCE = 'applied_for_assistance',
  ATTORNEY_FEE = 'attorney_fee',
  CIVIL_RECOVERY_FEES = 'civil_recovery_fees',
  CLAIMS = 'claims',
  COSTS = 'costs',
  DAMAGES = 'damages',
  DAMAGES_DESCRIPTION = 'damages_description',
  DELINQUENCY_LENGTH = 'delinquency_length',
  DOB = 'dob',
  EMAIL = 'email',
  EVICTION_REASON = 'eviction_reason',
  INTEREST_RATE = 'interest_rate',
  INTEREST_START_DATE = 'interest_start_date',
  IS_NOT_ACTIVE_MILITARY_MEMBER = 'is_not_active_military_member',
  LATE_FEES = 'late_fees',
  LEASE_RENT_AMOUNT = 'lease_rent_amount',
  LEASE_RENT_DUE_DAY = 'lease_rent_due_day',
  LEASE_RENT_FREQUENCY = 'lease_rent_frequency',
  LEASE_TERMS = 'lease_terms',
  MOVE_IN_DATE = 'move_in_date',
  NOT_ACTIVE_MILITARY = 'not_active_military',
  NOTICE_DATE = 'notice_date',
  PARTIAL_PAYMENTS = 'partial_payments',
  PAST_DUE_RENT = 'past_due_rent',
  PAST_DUE_RENT_HISTORY = 'past_due_rent_history',
  MIDDLE_NAME = 'middle_name',
  PHONE_NUMBER = 'phone_number',
  PROPERTY_COUNTY = 'property_county',
  PROPERTY_NAME = 'property_name',
  PROPERTY_TYPE = 'property_type',
  PROPERTY_UNIT_TYPES = 'property_unit_types',
  REASON = 'reason',
  SECURITY_DEPOSIT = 'security_deposit',
  SSN = 'ssn',
  WAIVER_OF_NOTICE = 'waiver_of_notice',
}
