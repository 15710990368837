import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dataLetters',
  standalone: false
})
export class DataLettersPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    let result = '';
    value.split(' ').forEach(word => {
      if (word && word.length > 0) {
        result = result + word[0];
      }
    });
    return result.toUpperCase();
  }
}
