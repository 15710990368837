import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ExpansionPanelComponent} from './expansion-panel.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';


@NgModule({
  imports: [CommonModule, MatIconModule, MatButtonModule],
  declarations: [ExpansionPanelComponent],
  exports: [ExpansionPanelComponent]
})
export class ExpansionPanelModule {}
