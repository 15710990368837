import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {IntakeOption, processForm} from '@ee/common/models';


export function GenerateTenantForm(fb: UntypedFormBuilder, options: Map<string, IntakeOption>,
  skipValidators = false): UntypedFormGroup {
  const form = fb.group({
    id: null,
    first_name: '',
    middle_name: '',
    last_name: '',
    ssn: '',
    email: '',
    dob: '',
    phone_number: '',
    is_not_active_military_member: null
  });

  if (!skipValidators) {
    const additionalValidators: Map<string, ValidatorFn[]> = new Map<string, ValidatorFn[]>();
    additionalValidators.set('first_name', [Validators.required]); // override name validator (always require)
    additionalValidators.set('last_name', [Validators.required]); // override name validator (always require)

    if (options['is_not_active_military_member']?.required && options['is_not_active_military_member']?.show) {
      additionalValidators.set('is_not_active_military_member', [Validators.requiredTrue]);
    }

    // add validators per attorney's specifications
    return processForm(form, options, additionalValidators);
  }
  return form;
}
