import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomFieldComponent} from './custom-field.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSelectModule} from '@angular/material/select';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {NgxMatTimepickerModule} from 'ngx-mat-timepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {TooltipModule} from 'ng2-tooltip-directive-major-angular-updates';
import {FieldComponent} from './field.component';
import {UploaderModule} from '@ee/common/uploader';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMatTimepickerModule,
    MatCheckboxModule,
    MatIconModule,
    MatRadioModule,
    TooltipModule,
    UploaderModule
  ],
  declarations: [CustomFieldComponent, FieldComponent],
  exports: [CustomFieldComponent, FieldComponent]
})
export class CustomFieldModule {
  public static forRoot(environment: any): ModuleWithProviders<CustomFieldModule> {
    return {
      ngModule: CustomFieldModule,
      providers: [{ provide: 'environment', useValue: environment }]
    };
  }
}
