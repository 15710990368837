export enum AttorneyUserRoles {
  ATTORNEY = 'Attorney',
  LEGAL_ASSISTANT = 'Legal Assistant',
  PARALEGAL = 'Paralegal',
  BOOKKEEPER = 'Bookkeeper',
  PROCESS_SERVER = 'Process Server'
}

export enum ClientUserRoles {
  CORPORATE_ADMIN = 'Corporate Administrator',
  ON_SITE_ADMIN = 'On-Site Administrator',
  REGIONAL_MANAGER = 'Regional Manager'
}

export enum GeneralUserRoles {
  RECEPTIONIST = 'Receptionist',
  INFORMATION_TECHNOLOGY = 'Information Technology'
}

// This creates a merged enum, but not a type
export const UserRoles = GeneralUserRoles || AttorneyUserRoles || ClientUserRoles;

// Workaround: create a named type (typeof Animals won't work here!)
export type UserRolesType = GeneralUserRoles | AttorneyUserRoles | ClientUserRoles;
