import {NgModule} from '@angular/core';
import {MatButtonLoadingDirective} from './mat-button-loading.directive';
import {DzDirective} from './dz.directive';
import {OptionsScrollDirective} from './options-scroll.directive';


@NgModule({
  declarations: [
    MatButtonLoadingDirective,
    OptionsScrollDirective,
    DzDirective
  ],
  exports: [
    MatButtonLoadingDirective,
    OptionsScrollDirective,
    DzDirective
  ]
})
export class EeDirectivesModule {}
