export enum CustomFieldType {
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  MULTI_TEXT = 'MULTI_TEXT',
  DATE = 'DATE',
  TIME = 'TIME',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  DECIMAL = 'DECIMAL',
  ARRAY = 'ARRAY',
  FILE_UPLOAD = 'FILE_UPLOAD',
  CALCULATED = 'CALCULATED'
}
