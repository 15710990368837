import {Component, OnDestroy, OnInit} from '@angular/core';
import {Organization} from '@ee/common/models';
import {OrganizationService} from '@ee/common/services';
import {STATES} from '@ee/common/constants';
import {Subscription} from 'rxjs';
import {IntakeEvictionService} from './services/intake-eviction.service';
import {LandingPageVisit} from './models/landing-page-visit.model';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {environment} from '../environments/environment';
import {BreakpointObserver, Breakpoints, BreakpointState} from '@angular/cdk/layout';

@Component({
  selector: 'ee-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  public attorney: Organization;
  public logo: string;
  public smallScreen = false;
  states = STATES;
  year = new Date().getFullYear();
  isLoading = true;
  isSuccess = false;
  subs: Subscription[] = [];
  landingPageVisit: LandingPageVisit;
  baseUrl = environment.base_url;

  constructor(
    private organizationService: OrganizationService,
    private intakeEvictionService: IntakeEvictionService,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit() {
    const subdomain = location.hostname.split('.').shift();

    // calculate screen size
    this.subs.push(this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        this.smallScreen = state.matches;
      }));

    this.subs.push(this.organizationService.getAttorneyByIdentifier(subdomain)
      .subscribe({
        next: (attorney: Organization) => {
          if (attorney) {
            this.attorney = attorney;
            this.subs.push(this.intakeEvictionService.addLandingPageVisit(this.attorney.id).subscribe(
              (landingPageVisit: LandingPageVisit) => {
                this.landingPageVisit = landingPageVisit;
              }
            ));
            if (attorney.has_logo) {
              this.logo = this.organizationService.generateAttorneyLogoUrl(attorney.attorney_key);
            }
            this.isLoading = false;
          }
        },
        error: () => {
          this.isLoading = false;
          this.attorney = null;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  evictionSaved() {
    this.intakeEvictionService.updatePageLoadVisits(this.landingPageVisit);
    this.isSuccess = true;
  }
}

