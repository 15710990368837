import {Inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {County, CountyFeeSetting} from '@ee/common/models';

@Injectable({ providedIn: 'root' })
export class CountyFeeSettingsService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  getByState(state: string): Observable<CountyFeeSetting[]> {
    return this.http.get<CountyFeeSetting[]>(`${this.environment.api_prefix}api/county-fee-settings/state/${state}`);
  }

  getByCounty(county: County): Observable<CountyFeeSetting | null> {
    if (!county) {
      return of(null);
    }
    return this.http.get<CountyFeeSetting | null>(`${this.environment.api_prefix}api/county-fee-settings/county/${county?.id}`);
  }

  update(setting: CountyFeeSetting) {
    return this.http.post<CountyFeeSetting>(`${this.environment.api_prefix}api/county-fee-settings`, setting);
  }
}
