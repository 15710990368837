import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import printJS from 'print-js';

@Injectable({providedIn: 'root'})
export class DocumentService {
  constructor(private http: HttpClient, @Inject('environment') private environment) {
  }

  loadDocument(key: string, responseType: any = 'blob'): Observable<any> {
    return this.http.get(`${this.environment.api_prefix}api/document/${encodeURI(key)}`, {responseType});
  }

  previewDocument(key: string): Observable<any> {
    return this.http.get(`${this.environment.api_prefix}api/document/${key}?preview=true`,
      {responseType: 'blob'});
  }

  saveDocument(filename: string, data: any) {
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    link.remove();
  }

  printDocument(key: string) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        const str = reader.result as string;

        // convert image file to base64 string
        printJS({printable: str.substring(str.indexOf(',') + 1), type: 'pdf', base64: true});
      },
      false,
    );

    this.loadDocument(key).subscribe((file) => {
      reader.readAsDataURL(file);
    });
  }

  async loadAndSaveDocument(path: string, filename: string = null) {
    const data = await firstValueFrom(this.loadDocument(path));
    const fileNameParts = path.split('/');
    this.saveDocument(filename ?? fileNameParts[fileNameParts.length - 1], data);
  }

  downloadCaseFiles(caseIds: string[]): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/document/case-files`, caseIds);
  }

  downloadInvoices(invoiceIds: string[]): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/document/invoice-pdfs`, invoiceIds);
  }

}
