import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CourtTask, BaseTask, User} from '@ee/common/models';
import {AccountType, CustomFieldType, TaskType} from '@ee/common/enums';
import {MtxPopover} from '@ng-matero/extensions/popover';

@Component({
  selector: 'ee-case-task',
  template: `
    <div class="case-task" [ngClass]="{'hide-border': hideBorder}">
      @if (completable) {
        <div class="complete-task" (click)="markCompleted()">
          @if (!!task?.marked_completed_date && !tempMarkIncomplete) {
            <i class="fa fa-check-circle"></i>
          } @else {
            <div class="unfinished" [ngClass]="{'temp-incomplete': tempMarkIncomplete}">
              <i class="far fa-circle"></i>
              <i class="fa fa-check-circle"></i>
            </div>
          }
        </div>
      }
      <div class="task-summary" [ngClass]="task?.type" (click)="onEdit.emit(task)">
        <div class="flex flex-row justify-between items-center">
          <div class="task-name-wrapper">
            @if (task?.required) {
              <span class="required" matTooltip="Required" [matTooltip]="task?.required ? 'Required' : ''">*</span>
            }
            <div class="task-name">
              {{ task?.name }}
            </div>
          </div>
          @if (assignedUsers?.length) {
            <div class="flex flex-row justify-start items-center">
              <span *ngFor="let user of assignedUsers" class="small" [matTooltip]="user.display_name" [matTooltipPosition]="'above'"
                    [matTooltipShowDelay]="500" [matTooltipHideDelay]="1000" [attr.data-letters]="user.display_name | dataLetters"
                    [ngStyle]="{'--user-selector-color': user.color}"></span>
            </div>
          }
        </div>
        <div *ngIf="task?.description" class="flex-1 task-description">
          {{ task.description }}
        </div>
        @if (showRolesRow) {
          <div class="roles-row">
            <div class="flex flex-row justify-start items-center mat-chips status flex-wrap">
              <span class="mat-chip blue" *ngFor="let role of task.roles">{{ role }}</span>
            </div>
          </div>
        }
        @if (showThirdRow) {
          <div class="bottom-row">
            @if (task?.required) {
              <span class="required">Required</span>
            }
            @if (task?.due_date) {
              <div class="task-due-date">
                <span class="task-due-date-label"><i class="far fa-calendar-day"></i></span>
                <span>{{ task?.due_date | dateTimeFromIso | dateTimeToFormat:'MMM d, yyyy' }}</span>

                @if (!task?.marked_completed_date && !!task.days_remaining && task.days_remaining < 8) {
                  <span class="days-remaining" *ngIf="task.days_remaining > 0">
                    {{ task.days_remaining }} day{{ task.days_remaining > 1 ? 's' : '' }} left
                  </span>
                  <span class="days-remaining" *ngIf="task.days_remaining === 0">Due today</span>
                  <span class="days-remaining" *ngIf="task.days_remaining < 0">
                    {{ task.days_remaining * -1 }} day{{ task.days_remaining < -1 ? 's' : '' }} overdue
                  </span>
                }
              </div>
            }
            @if (task?.remove_on_step_completion) {
              <i class="fas fa-octagon-xmark extra"
                 matTooltip="This task will be removed when step is processed unless it is marked as completed"></i>
            }
            @if (!!task?.marked_completed_date) {
              <div class="text-end italic flex-1" [matTooltip]="'Completed by ' + task.marked_completed_by">{{ task.marked_completed_by }}</div>
            }
          </div>
        }
      </div>
    </div>
  `,
  styles: [
    `
      @import 'components/color-palette';

      .case-task {
        flex-direction: row;
        justify-content: stretch;
        align-items: flex-start;
        padding: 8px;
        border: 1px solid #ccc;
        background-color: #fff;
        display: flex;
        user-select: none;

        &.hide-border {
          border: none;
        }

        .complete-task {
          height: 24px;
          margin-right: 8px;
          z-index: 1;
          cursor: pointer;

          .fa-check-circle, .fa-circle {
            font-size: 24px;
          }

          .fa-circle {
            color: #AEAEAE;
          }

          .fa-check-circle {
            color: #61BF57;
          }

          .unfinished {
            display: inline-block;
            width: auto;

            .fa-circle {
              display: block;
            }

            .fa-check-circle {
              display: none;
            }

            &:hover {
              .fa-circle {
                display: none;
              }

              .fa-check-circle {
                display: block;
              }
            }

            &.temp-incomplete:hover .fa-check-circle {
              .fa-circle {
                display: block;
              }

              .fa-check-circle {
                display: none;
              }
            }
          }
        }

        &:hover {
          background-color: #f9f9f9;
        }

        .task-summary {
          position: relative;
          color: var(--dark-gray);
          display: flex;
          flex-direction: column;
          overflow: hidden;
          cursor: pointer;
          flex: 1;

          .task-name-wrapper {
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            flex-direction: row;
            flex: 1;

            .task-name {
              font-weight: 600;
              color: var(--dark-gray);
              font-size: 14px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              max-width: 100%;
            }

            .required {
              color: var(--primary-red);
              font-size: 14px;
            }
          }

          .task-description {
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: var(--dark-gray);
            opacity: 0.7;
            font-size: 12px;
            font-weight: 500;
          }

          .roles-row, .bottom-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }

          .roles-row {
            margin-top: 2px;

            .mat-chips.status {
              row-gap: 4px;
              font-size: 10px;
              padding: 4px 0;
            }
          }

          .bottom-row {
            margin-top: 2px;
            font-size: 12px;

            .required {
              color: var(--primary-red);
              font-weight: 600;
              margin-right: 6px;
            }

            .fa.extra {
              font-weight: 600;
              font-size: 12px;
              display: inline-block;
              margin-right: 6px;
            }

            .task-due-date {
              text-wrap: nowrap;
              align-self: start;
              color: var(--dark-gray);
              font-size: 12px;
              font-weight: 500;
              margin-right: 6px;

              .task-due-date-label {
                font-size: 12px;
                font-weight: 500;
                margin-right: 2px;
              }

              .days-remaining {
                font-weight: 500;
                color: var(--primary-red);
                margin-left: 4px;
              }
            }
          }
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CaseTaskComponent implements OnInit, OnChanges {
  public isOverdue = false;
  public taskTypes = TaskType;
  public fieldTypes = CustomFieldType;
  public assignedUsers: User[] = [];
  public showRolesRow = false;
  public showThirdRow = false;
  public tempMarkIncomplete = false;

  @Input({required: true})
  public task: CourtTask | BaseTask;

  @Input()
  public editable = true;

  @Input()
  public completable = true;

  @Input()
  public deletable = true;

  @Input() hideBorder = false;

  @Input()
  public dueDateOnlyChangeable = false;

  @Input()
  public users: User[] = [];

  @Output()
  public onEdit = new EventEmitter<BaseTask>();

  @Output()
  public onMarkCompleted = new EventEmitter<BaseTask>();

  ngOnInit() {
    this.isOverdue = this.task?.days_remaining && this.task?.days_remaining <= 3;
    this.showRolesRow = !!this.task?.roles?.length;
    this.showThirdRow = this.task?.required || !!this.task?.due_date || this.task?.remove_on_step_completion || !!this.task?.marked_completed_date;
  }

  ngOnChanges() {
    this.assignedUsers = this.users?.filter(user => this.task.assigned_users.includes(user.id))
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    this.tempMarkIncomplete = false;
  }

  markCompleted() {
    this.task.marked_completed_date = !this.task.marked_completed_date ? new Date().toISOString() : null;
    if (!this.task.marked_completed_date) {
      this.tempMarkIncomplete = true;
    }
    this.onMarkCompleted.emit(this.task);
  }

  protected readonly AccountType = AccountType;
  protected readonly MtxPopover = MtxPopover;
  protected readonly CustomFieldType = CustomFieldType;
}
