import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DateTime} from 'luxon';
import {UserService} from '@ee/common/services';
import {ValidateEmailNotTaken} from '@ee/common/validators';

export type UserForm = FormGroup<{
  title: FormControl<string | null>;
  email: FormControl<string>;
  first_name: FormControl<string>;
  last_name: FormControl<string>;
  time_zone: FormControl<string>;
  phone_number: FormControl<string | null>;
  tos_policy_confirm: FormControl<boolean | null>;
  policy_confirm: FormControl<boolean | null>;
}>;

export function GenerateUserForm(fb: FormBuilder): UserForm {
  return fb.group({
    title: new FormControl<string | null>(''),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email]
    }),
    first_name: new FormControl<string>('', Validators.required),
    last_name: new FormControl<string>('', Validators.required),
    time_zone: new FormControl<string>(DateTime.local().zoneName, Validators.required),
    phone_number: new FormControl<string>('', Validators.required),
    tos_policy_confirm: new FormControl<boolean | null>(null),
    policy_confirm: new FormControl<boolean | null>(null)
  });
}

export function GenerateUserRegistrationForm(fb: FormBuilder, userService: UserService): UserForm {
  return fb.group({
    title: new FormControl<string | null>(null),
    email: new FormControl<string>('', {
      validators: [Validators.required, Validators.email],
      asyncValidators: [ValidateEmailNotTaken.createValidator(userService)],
      updateOn: 'blur'
    }),
    first_name: new FormControl<string>('', Validators.required),
    last_name: new FormControl<string>('', Validators.required),
    time_zone: new FormControl<string>(DateTime.local().zoneName, Validators.required),
    phone_number: new FormControl<string | null>(null),
    tos_policy_confirm: new FormControl<boolean | null>(false),
    policy_confirm: new FormControl<boolean | null>(false)
  });
}
