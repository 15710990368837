import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import findKey from 'lodash-es/findKey';
import forEach from 'lodash-es/forEach';
import {Subscription} from 'rxjs';
import {
  AddressSuggestion,
  County,
  IntakeFormSection,
  IntakeOption,
  Organization
} from '@ee/common/models';
import {AccountType, IntakeFormFields, UNIT_TYPES} from '@ee/common/enums';
import {STATES} from '@ee/common/constants';
import {CountyService} from '@ee/common/services';
import find from 'lodash-es/find';

@Component({
  selector: 'ee-eviction-property-section',
  template: `
    <div class="properties-form section-wrapper" [formGroup]="form">
      <div class="flex flex-row justify-start items-center" *ngIf="options[intakeFields.PROPERTY_TYPE].show">
        <div class="property-type-label mr-4">Property Type:</div>
        <mat-radio-group class="mr-4" aria-label="Property Type" formControlName="property_type">
          <mat-radio-button class="mr-2" [value]="'Conventional'">
            Conventional
          </mat-radio-button>
          <mat-radio-button class="mr-2" [value]="'Public Housing'">
            Public Housing
          </mat-radio-button>
          <mat-radio-button class="mr-2" [value]="'Section 8'">
            Section 8
          </mat-radio-button>
        </mat-radio-group>
        <mat-error *ngIf="form.get('property_type').touched && form.get('property_type').invalid">Required</mat-error>
      </div>

      <div class="in-row-grid">
        <mat-form-field class="quad-column" *ngIf="options[intakeFields.PROPERTY_NAME].show">
          <mat-label>Property Name</mat-label>
          <input matInput formControlName="property_name">
          <mat-error *ngIf="form.get('property_name').hasError('required')">
            A property name is required.
          </mat-error>
          <mat-hint>
            {{options[intakeFields.PROPERTY_NAME].required ? 'Required' : 'Optional' }}. An apartment or building name.
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="in-row-grid" formGroupName="custom_fields"
           *ngIf="propertySection?.fields?.length && propertyFields">
        <ee-field *ngFor="let f of propertySection?.fields; let i = index"
                  [ngClass]="{'margin-bottom-sm': i < (propertySection.fields.length - 1),
                              'md:mb-4': i < (propertySection.fields.length - 1),
                              'double-column': f.flex === 2, 'triple-column': f.flex === 3, 'quad-column': f.flex === 4}"
                  [hideFromIntake]="f.hide_from_intake"
                  [hidden]="f.hide_from_intake && !overrideFieldVisibility"
                  [control]="$any(propertyFields.controls[f.field])" [field]="f">
        </ee-field>
      </div>

      <div class="in-row-grid">
        <ee-address-autocomplete class="double-column" placeholder="Street Address"
                                 [formControl]="$any(form.get('property_street_address'))"
                                 (selectionChange)="onAddressSelected($event)">
        </ee-address-autocomplete>
        <mat-form-field class="portal-remove-fixed-infix-width">
          <mat-label>Unit Type</mat-label>
          <mat-select formControlName="property_unit_type">
            <mat-option [value]="null">
              &nbsp;
            </mat-option>
            <mat-option *ngFor="let type of unitTypeOptions" [value]="type">
              {{type}}
            </mat-option>
          </mat-select>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Unit #</mat-label>
          <input matInput formControlName='property_unit_number'>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
      <div class="in-row-grid">
        <mat-form-field class="double-column">
          <mat-label>City</mat-label>
          <input matInput formControlName="property_city">
          <mat-error *ngIf="form?.get('property_city').hasError('required')">
            Please enter a city.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>State</mat-label>
          <mat-select id="state" formControlName="property_state"
                      (selectionChange)="stateChanged()">
            <mat-option *ngFor="let state of states | keyvalue" [value]="state.key">
              {{state.value}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form?.get('property_state').hasError('required')">
            Please select a state.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Zip Code</mat-label>
          <input matInput mask="00000" formControlName="property_zip_code"
                 (change)="stateChanged()">
          <mat-error *ngIf="form?.get('property_zip_code').hasError('required')">
            Please enter a zip code.
          </mat-error>
          <mat-hint>Required</mat-hint>
        </mat-form-field>
        <mat-form-field class="county" *ngIf="loggedInOrg?.type === attorneyType || loggedInOrg?.id === selectedAttorneyId ||
           (options[intakeFields.PROPERTY_COUNTY] && options[intakeFields.PROPERTY_COUNTY].show)">
          <mat-label>County</mat-label>
          <mat-select formControlName="county" [disabled]="countyClientOverridden && loggedInOrg?.type !== attorneyType"
                      [required]="options[intakeFields.PROPERTY_COUNTY] && options[intakeFields.PROPERTY_COUNTY].required">
            <mat-option>-- None --</mat-option>
            <mat-option *ngFor="let county of counties" [value]="county">
              {{county.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="form?.get('county')?.hasError('required')">
            Please select a county.
          </mat-error>
          <mat-hint>
            <span *ngIf="countyClientOverridden" class="blue cursor-pointer"
                  matTooltip="The county selection has been override with your client county rules.">
              <i class="fa fa-info-circle blue mt-3"></i>
              Overridden via rule.
            </span>
            <span *ngIf="countyMatches > 1 && !countyClientOverridden && loggedInOrg?.type === attorneyType"
                  class="red cursor-pointer"
                  matTooltip="This zip code spans more than one county.
                    Consider setting up a client county rule to automatically override the county
                    for this zip code.">
                <i class="fa fa-info-circle red mt-3"></i>
                Multiple matches.
              </span>
            {{options[intakeFields.PROPERTY_COUNTY].required ? 'Required' : 'Optional' }}
          </mat-hint>
        </mat-form-field>
        <mat-form-field *ngIf="districtCourts">
          <mat-label>District Court</mat-label>
          <mat-select formControlName="district_court_name">
            <mat-option *ngFor="let dc of districtCourts" [value]="dc">
              {{dc}}
            </mat-option>
          </mat-select>
          <mat-hint>Optional</mat-hint>
        </mat-form-field>
      </div>
    </div>
  `,
  styles: [`
    @import '../section-styles';
    @import 'components/color-palette';

    .margin-bottom {
      margin-bottom: 1rem;
    }
    .margin-bottom-sm {
      margin-bottom: .5rem;
    }
    .property-type-label {
      font-size: 1rem;
    }

    .county {
      .red {
        color: var(--primary-red);
      }
      .blue {
        color: var(--c-blue);
      }
    }
  `],
  standalone: false
})
export class EvictionPropertySectionComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() demoMode = false;

  @Input() form: UntypedFormGroup;

  @Input() loggedInOrg?: Organization;

  @Input() selectedAttorneyId: string;

  @Input() options: Map<string, IntakeOption> | undefined;

  @Input() plaintiffHistory: string[];

  @Input() overrideFieldVisibility = false;

  @Input() propertySection: IntakeFormSection | undefined;

  @Input() selectedClient: Organization | undefined = undefined;

  intakeFields = IntakeFormFields;
  states = STATES;
  counties: County[];
  attorneyType = AccountType.ATTORNEY;
  countyMatches = 0;
  countyClientOverridden = false;
  districtCourts: string[] | undefined = undefined;
  initialCounty: County | undefined = undefined;

  private subs: Subscription[] = [];

  constructor(private countyService: CountyService) {
  }

  ngOnInit() {
    if (this.loggedInOrg && this.form.get('property_state').value && this.form.get('property_zip_code').value) {
      this.stateChanged(false);
    }
    this.initialCounty = this.form.get('county')?.value ?? undefined;
  }

  ngAfterViewInit() {
    if (this.form.get('county')) {
      this.subs.push(this.form.get('county').valueChanges.subscribe((selectedCounty: County | undefined) => {
        this.districtCourts = selectedCounty?.district_courts ? selectedCounty.district_courts.map(c => c.name) : null;

        // pre-select the district court if it's not already selected
        if (!this.initialCounty || this.initialCounty.id !== selectedCounty?.id) {
          if (selectedCounty) {
            // select district court based on selected city - or first one
            if (this.districtCourts?.length > 0) {
              const city = this.form.get('property_city').value;
              const districtCourt = selectedCounty.district_courts.find(c => c.cities?.includes(city));
              if (districtCourt) {
                this.form.get('district_court_name').setValue(districtCourt.name);
              } else {
                this.form.get('district_court_name').setValue(selectedCounty.district_courts[0]?.name);
              }
            } else {
              this.form.get('district_court_name').setValue(null);
            }
          } else {
            this.districtCourts = null;
            this.form.get('district_court_name').setValue(null);
          }
        }
      }));
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  get propertyFields(): UntypedFormGroup | undefined {
    return this.form.get('custom_fields') as UntypedFormGroup;
  }

  onAddressSelected(result: AddressSuggestion) {
    const state = findKey(STATES, (s) => s === result.state_full);

    this.form.get('property_street_address').setValue(result.street_address);
    this.form.get('property_city').setValue(result.city);
    this.form.get('property_state').setValue(state);
    this.form.get('property_zip_code').setValue(result.zip_code);
    this.form.get('property_lat').setValue(result.lat);
    this.form.get('property_lng').setValue(result.lng);

    // look up counties
    this.stateChanged();
  }

  stateChanged(updateSelectedCounty = true): void {
    const state = this.form.get('property_state').value;
    const zipCode = this.form.get('property_zip_code').value;
    if (state) {
      this.subs.push(this.countyService.getCountiesByState(state).subscribe(
        (counties: County[]) => this.processCounties(state, counties, zipCode, updateSelectedCounty)));
    }
  }

  public get unitTypeOptions(): string[] {
    let options = [...UNIT_TYPES];
    if (this.options && this.options[this.intakeFields.PROPERTY_UNIT_TYPES]?.options?.length) {
      options = this.options[this.intakeFields.PROPERTY_UNIT_TYPES]?.options;
    }

    return options;
  }

  private processCounties(state: string, counties: County[], zipCode: string = null,
    updateSelectedCounty = true) {
    this.counties = [];
    this.countyMatches = 0;
    this.countyClientOverridden = false;

    if (updateSelectedCounty) {
      this.form.patchValue({county: null});
    }

    let zipCodeInt = 0;
    if (zipCode) {
      zipCodeInt = parseInt(zipCode, 10);
    }

    forEach(counties, (county: County) => {
      county.matches_zip_code = zipCodeInt > 0 ? county.zip_codes.indexOf(zipCodeInt) > -1 : false;
      if (county.matches_zip_code) {
        this.counties.unshift(county);
        this.countyMatches++;

        if (updateSelectedCounty) {
          // auto-choose first form
          this.form.patchValue({county});
        }
      } else {
        this.counties.push(county);
      }
    });

    // override selected county if it's in the override list
    if (updateSelectedCounty && this.selectedClient?.county_override_rules?.length && state && zipCodeInt > 0) {
      const matchingCountyOverrideRule = find(this.selectedClient.county_override_rules,
        {
          state,
          zip_code: zipCodeInt
        }) ;
      if (matchingCountyOverrideRule) {
        const matchingCounty = find(this.counties, {id: matchingCountyOverrideRule.county_id});
        if (matchingCounty) {
          this.form.patchValue({county: matchingCounty});
          this.countyClientOverridden = true;
        }
      }
    }

    // reselect county to make sure it's selected
    if (!updateSelectedCounty) {
      const county = this.counties.find(c => c.id === this.form.value.county?.id);
      this.form.patchValue({county});
    }
  }
}
