import {Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {
  DocumentTemplate,
  Organization,
  User,
  Workflow,
  WorkflowStep
} from '@ee/common/models';
import {AccountType, AttorneyUserRoles} from '@ee/common/enums';
import {ClientSelectComponent} from '@ee/common/client-selection';
import {FormService, UserService} from '@ee/common/services';
import {Observable} from 'rxjs';
import {IntakeEvictionFormService} from '../services/intake-eviction-form.service';

@Component({
  selector: 'ee-workflow-form',
  template: `
    <div class="flex flex-1 flex-col" [formGroup]="this.intakeEvictionFormService.workflowDetails">
      <ee-expansion-panel header="Client" [collapsible]="false" class="mb-4"
                          *ngIf="this.intakeEvictionFormService.loggedInOrgType === accountTypes.ATTORNEY">
        <div *ngIf="hasClients">Select the client who requested the eviction.</div>
        <div class="mt-2 flex flex-col">
          <ee-client-select #clientSelect [initClientId]="selectedClient?.id"
                            (selectedClientChange)="selectClient($event)" (clientCount)="updateClientCount($event)"
                            *ngIf="hasClients; else noClients"></ee-client-select>
          <ng-template #noClients>
            <div *ngIf="!hasClients" class="card-content no-top-padding flex flex-col justify-center items-center">
              <p class="mb-4">Welcome to EasyEviction! Let's set up a new client so you can file your first eviction.</p>
              <button mat-flat-button color="accent" (click)="handleAddClient()">
                Add Client
              </button>
            </div>
          </ng-template>
        </div>
      </ee-expansion-panel>
      <ee-expansion-panel header="Case Assignment" [collapsible]="false" class="mb-4"
                          *ngIf="(attorneyUsers$ | async) as attorneyUsers">
        <div class="flex flex-row justify-center align-middle">
          <mat-form-field class="compact self-center" *ngIf="attorneyUsers.length; else noAttorneys">
            <mat-label>Attorney Assigned to Case</mat-label>
            <mat-select (valueChange)="selectAssignedAttorney($event)">
              <mat-option [value]="undefined">-- None --</mat-option>
              <mat-option *ngFor="let attorney of attorneyUsers" [value]="attorney.id">
                {{attorney.first_name}} {{attorney.last_name}}
              </mat-option>
            </mat-select>
            <mat-hint>Optional</mat-hint>
          </mat-form-field>
          <ng-template #noAttorneys>
            One or more users with an Attorney role required.
          </ng-template>
        </div>
      </ee-expansion-panel>

      <ee-expansion-panel header="Workflow" [collapsible]="false" class="mb-4"
                          *ngIf="intakeEvictionFormService.loggedInOrgType === accountTypes.ATTORNEY">
        <div class="flex flex-col">
          <div>Select the workflow to use for this eviction.</div>
          <div class="mt-2 flex flex-col flex-1" *ngIf="(intakeEvictionFormService.workflows$ | async) as workflows">
            <mat-list class="styled-list">
              <mat-list-item *ngFor="let workflow of workflows" (click)="selectWorkflow(workflow)" class="client-row"
                             [ngClass]="{'selected': workflow?.id === intakeEvictionFormService.workflowDetails?.controls.workflow_id.value}">
                <div mat-line class="flex flex-row justify-between items-center">
                  <div class="primary">{{workflow?.name}}</div>
                  <div class="secondary">{{workflow?.step_count}} Steps</div>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </ee-expansion-panel>
    </div>
  `,
  styles: [`
      @import 'responsive.mixin.scss';

      .custom-fields {
          display: grid !important;
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
          grid-auto-rows: 1fr;
          grid-column-gap: 1rem;
          grid-row-gap: 1rem;
          overflow: hidden;
      }

      .highlighted-box {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: flex-start;
          align-items: stretch;
          background-color: #F5F5F5;
          padding: 8px 8px 0;

          &:last-of-type {
              padding-bottom: 8px;
          }

          .highlighted-box-label {
              text-transform: capitalize;
              font-weight: 600;
              font-size: 16px;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              margin-bottom: 10px;

              .highlighted-box-sub-label {
                  font-size: 12px;
                  font-weight: normal;
              }
          }
      }
  `],
  standalone: false
})
export class WorkflowFormComponent implements OnInit, OnChanges {
  @ViewChild('clientSelect', {static: false}) clientSelect: ElementRef<ClientSelectComponent>;

  @Output() addClient: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() workflowSelected: EventEmitter<Workflow> = new EventEmitter<Workflow>();

  query: string;
  accountTypes = AccountType;
  attorneyUsers$: Observable<User[]>;
  forms$: Observable<DocumentTemplate[]>;
  hasClients = true;
  public intakeStep: WorkflowStep | null;

  constructor(public intakeEvictionFormService: IntakeEvictionFormService,
              private userService: UserService, private formService: FormService) {
  }

  ngOnInit() {
    this.attorneyUsers$ = this.userService.getUsers(null, [AttorneyUserRoles.ATTORNEY], true);
    this.forms$ = this.formService.getAllForms();
  }

  ngOnChanges() {

    // if (this.intakeEvictionFormService.loggedInOrgType === AccountType.CLIENT) {
    //   this.intakeEvictionFormService.selectAttorney(this.intakeEvictionFormService.attorneyId);
    // }

    if (this.intakeEvictionFormService.workflowDetails.controls.workflow_id?.value && this.intakeEvictionFormService.workflows?.length) {
      const selectedWorkflow =
        this.intakeEvictionFormService.workflows.filter(w => w.id === this.intakeEvictionFormService.defaultWorkflowId);
      this.selectWorkflow(selectedWorkflow[0]);
    }
  }

  handleAddClient() {
    this.addClient.emit(false);
  }

  get selectedClient(): Organization {
    return this.intakeEvictionFormService.workflowDetails.controls.client_details.value;
  }

  selectClient(client: Organization) {
    this.intakeEvictionFormService.updateClient(client);
    this.intakeEvictionFormService.workflowDetails.controls.client_details.setValue(client);
    if (this.intakeStep && this.intakeEvictionFormService.workflowDetails.controls.workflow_id.value) {
      const selectedWorkflow = this.intakeEvictionFormService.workflows.find(s => s.id === this.intakeEvictionFormService.workflowDetails.controls.workflow_id.value);
      if (selectedWorkflow) {
        this.selectWorkflow(selectedWorkflow);
      }
    }
  }

  selectWorkflow(workflow: Workflow) {
    if (workflow) {
      this.intakeEvictionFormService.workflowDetails.controls.workflow_id.setValue(workflow.id);
      this.intakeStep = workflow.steps.filter(s => s.step === 1)[0];
      this.workflowSelected.emit(workflow);
    } else {
      this.intakeStep = undefined;
    }
  }

  selectAssignedAttorney(attorneyUserId: string | undefined) {
    this.intakeEvictionFormService.updateAttorneyUser(attorneyUserId);
  }

  updateClientCount(count: number) {
    this.hasClients = count > 0;
  }
}
