import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadSelectorComponent } from './upload-selector.component';
import { UploaderComponent } from './uploader.component';
import { MatIconModule } from '@angular/material/icon';
import { EePipesModule, NgBytesPipeModule } from '@ee/common/pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  imports: [CommonModule, MatIconModule, EePipesModule, ReactiveFormsModule, MatButtonModule, NgBytesPipeModule],
  declarations: [UploadSelectorComponent, UploaderComponent],
  exports: [UploadSelectorComponent, UploaderComponent]
})
export class UploaderModule {
  public static forRoot(environment: any): ModuleWithProviders<UploaderModule> {
    return {
      ngModule: UploaderModule,
      providers: [{ provide: 'environment', useValue: environment }]
    };
  }
}
