import {FormBuilder, UntypedFormBuilder, Validators} from '@angular/forms';
import {AccountType} from '@ee/common/enums';
import split from 'lodash-es/split';
import map from 'lodash-es/map';
import {FormModel, InferModeFromModel, InferModeOptional} from 'ngx-mf';
import {Organization} from '@ee/common/models';

export type OrganizationForm = FormModel<Organization, null, InferModeOptional & InferModeFromModel>

export function GenerateOrganizationForm(fb: FormBuilder, type: AccountType, limitValidation = false): OrganizationForm {
  if (type === AccountType.CLIENT) {
    return fb.group({
      id: null,
      attorney_key: null,
      company_name: ['', Validators.required],
      company_name_2: null,
      street_address_1: ['', Validators.required],
      street_address_2: null,
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip_code: ['', Validators.required],
      lat: null,
      lng: null,
      main_email: [null, {
        validators: limitValidation ? [] : [Validators.required, Validators.email]
      }],
      main_phone_number: [null, limitValidation ? [] : [Validators.required]],
      main_fax_number: null,
      invoice_email: [null, limitValidation ? [] : [Validators.email]],
      invoice_vendor_id: null,
      invoice_street_address_1: ['', limitValidation ? [] : [Validators.required]],
      invoice_street_address_2: null,
      invoice_city: ['', limitValidation ? [] : [Validators.required]],
      invoice_state: ['', limitValidation ? [] : [Validators.required]],
      invoice_zip_code: ['', limitValidation ? [] : [Validators.required]],
      type,
      test: null
    }, { nonNullable: false });
  } else {
    return fb.group({
      id: null,
      attorney_key: ['', Validators.required],
      company_name: ['', Validators.required],
      company_name_2: null,
      street_address_1: ['', Validators.required],
      street_address_2: null,
      city: ['', Validators.required],
      state: ['', Validators.required],
      zip_code: ['', Validators.required],
      lat: null,
      lng: null,
      main_email: [null, {
        validators: [Validators.required, Validators.email]
      }],
      invoice_email: [null, Validators.email],
      reply_to_email: [null, {
        validators: [Validators.required, Validators.email]
      }],
      main_phone_number: [null, Validators.required],
      main_fax_number: null,
      type
    }, { nonNullable: false });
  }
}

export function GenerateClientIdentifier(companyName: string) {
  const words = split(companyName, ' ');
  const output = map(words, (item: string) => {
    const firstChar = item.charAt(0);
    return firstChar ? firstChar.toUpperCase() : '';
  });
  return output.join('');
}

export function GenerateAttorneyOrganizationSignUpForm(fb: UntypedFormBuilder) {
  return fb.group({
    company_name: ['', Validators.required],
    type: AccountType.ATTORNEY
  });
}

export function GenerateManagedOrganizationSignUpForm(fb: UntypedFormBuilder) {
  return fb.group({
    company_name: ['', Validators.required],
    company_name_2: null,
    street_address_1: ['', Validators.required],
    street_address_2: [null],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip_code: ['', Validators.required],
    lat: [null],
    lng: [null],
    main_phone_number: ['', Validators.required],
    logo: [null]
  });
}
