import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Self
} from '@angular/core';
import {ControlValueAccessor, NgControl} from '@angular/forms';
import {debounceTime, filter, finalize, switchMap, tap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {SearchService} from '@ee/common/services';
import {AddressSuggestion} from '@ee/common/models';

@Component({
  selector: 'ee-address-autocomplete',
  template: `
    <mat-form-field class="w-full" [ngClass]="{'compact': compact}">
      <mat-label>{{placeholder}}</mat-label>
      <input matInput [matAutocomplete]="auto" matAutocompletePosition="below" [formControl]="$any(controlDir.control)">
      <mat-error *ngIf="controlDir.control.invalid && controlDir.control.errors?.required">
        Please enter the street address.
      </mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngIf="isLoading; else optionsTemplate" class="is-loading" disabled="true">Loading...</mat-option>
        <ng-template #optionsTemplate>
          <mat-option *ngFor="let address of filteredAddresses" [value]="address.street_address"
                      (onSelectionChange)="selectResult(address)">
            {{address.display}}
          </mat-option>
        </ng-template>
      </mat-autocomplete>
      <mat-hint>{{required ? 'Required' : 'Optional'}}</mat-hint>
    </mat-form-field>
  `,
  styles: [],
  standalone: false
})
export class AddressAutocompleteComponent implements ControlValueAccessor, OnDestroy {
  public filteredAddresses: AddressSuggestion[];
  public selection: AddressSuggestion;
  public isLoading = false;
  public noResults = false;
  private subs: Subscription[] = [];
  private lengthToTriggerSearch = 3;

  @Input() compact: boolean;
  @Input() required = true;
  @Input() placeholder = 'Street Address';
  @Output() selectionChange = new EventEmitter<AddressSuggestion>();

  constructor(private searchService: SearchService,
              @Self() public controlDir: NgControl) {
    this.controlDir.valueAccessor = this;
  }

  // @ts-ignore
  writeValue(): void {
  }

  /**
   * Allows Angular to register a function to call when the inputControl changes.
   */
  registerOnChange(): void {
    this.subs.push(this.controlDir.valueChanges.pipe(
      debounceTime(500),
      // filter out if set programmatically
      filter(value => !this.isMinLength(value) || !this.selection ||
        this.selection.display !== value || this.selection.street_address !== value),
      tap(() => {
        this.isLoading = true;
      }),
      switchMap(value => this.searchService.searchAddress(value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          })
        ))
    ).subscribe((data) => {
      this.filteredAddresses = data;
    }));
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  selectResult(suggestion: AddressSuggestion) {
    this.selectionChange.emit(suggestion);
  }

  /**
   * Function to call when the input is touched.
   */
  onTouched() {
  }

  /**
   * Method linked to the mat-autocomplete `[displayWith]` input.
   * This is how result name is printed in the input box.
   */
  displayFn(result: AddressSuggestion): string | undefined {
    return result ? result.street_address : undefined;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  isMinLength(value: string) {
    return value?.length ?? 0 >= this.lengthToTriggerSearch;
  }
}
