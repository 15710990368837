import {Inject, Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {DocumentTemplate, Eviction, FillableFieldSection} from '@ee/common/models';
import {
  degrees,
  PDFArray,
  PDFDict,
  PDFDocument, PDFFont,
  PDFHexString,
  PDFName,
  PDFString,
  rgb,
  StandardFonts,
  PDFOperatorNames as Ops,
  PDFNumber, PDFContentStream, popGraphicsState, drawText, pushGraphicsState, translate, rotateDegrees, asPDFName, PDFOperator
} from 'pdf-lib';
import {DocumentService} from './document.service';
import {AttachmentType} from '@ee/common/enums';
import {forEach} from 'lodash-es';


@Injectable({ providedIn: 'root' })
export class FormService {
  constructor(private http: HttpClient, private documentService: DocumentService,
              @Inject('environment') private environment) {
  }

  findById(id: string): Observable<DocumentTemplate> {
    return this.http.get<DocumentTemplate>(`${this.environment.api_prefix}api/forms/${id}`);
  }

  searchForms(query: string): Observable<DocumentTemplate[]> {
    return this.http.get<DocumentTemplate[]>(`${this.environment.api_prefix}api/forms/search`, {
      params: {
        q: encodeURIComponent(query)
      }
    });
  }

  getFormSuggestions(countyId: string, state: string, attachmentType: string): Observable<DocumentTemplate[]> {
    const params: any = {
      state: encodeURIComponent(state),
      type: encodeURIComponent(attachmentType)
    };

    if (countyId) {
      params.countyId = encodeURIComponent(countyId);
    }

    return this.http.get<DocumentTemplate[]>(`${this.environment.api_prefix}api/forms/recommendations`, {
      params
    });
  }

  getFormsByType(type: AttachmentType): Observable<DocumentTemplate[]> {
    return this.http.get<DocumentTemplate[]>(`${this.environment.api_prefix}api/forms/type/${type}`);
  }

  getAllForms(includePublic = false, includeDisabled = false): Observable<DocumentTemplate[]> {
    const params = {
      'include-public': includePublic ? 'true' : 'false',
      'include-disabled': includeDisabled ? 'true' : 'false'
    };
    return this.http.get<DocumentTemplate[]>(`${this.environment.api_prefix}api/forms`, {
      params
    });
  }

  getFillableFields(): Observable<FillableFieldSection[]> {
    return this.http.get<FillableFieldSection[]>(`${this.environment.api_prefix}api/forms/fillable-fields`);
  }

  generate(formId: string, evictionDetails: Eviction): Observable<any> {
    return this.http.post(`${this.environment.api_prefix}api/forms/${formId}/generate`, evictionDetails, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  // generate preview of filled out form
  async generatePreview(formPath: string, formFillDetails: Map<string, string>) {
    const existingPdfBytes = await firstValueFrom(this.documentService.loadDocument(formPath, 'arrayBuffer'));

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    // Get the first page of the document
    const pages = pdfDoc.getPages();
    pages.forEach((page) => {
      // Get the width and height of the first page
      const {width, height} = page.getSize();

      // Draw a string of text diagonally across the first page
      page.drawText('EasyEviction Preview', {
        x: width / 4,
        y: (height / 4) * 3,
        size: 50,
        font: helveticaFont,
        color: rgb(0.8275, 0.8275, 0.8275),
        rotate: degrees(-45),
      });
    });

    // Fill Form ---------------------------------------------
    const fillInField = (fieldName: string, text: string) => {
      const field = this.findAcroFieldByName(pdfDoc, fieldName);
      if (field) {
        this.fillAcroTextField(field, text, helveticaFont);
      }
    };

    // @ts-ignore
    forEach(formFillDetails, (value: string, key: string) => {
      fillInField(key, value);
    });

    const pdfBytes = await pdfDoc.save();

    // download the generated pdf doc
    const file = new Blob([pdfBytes], {type: 'application/pdf'});
    const fileURL = URL.createObjectURL(file);
    const anchor = window.document.createElement('a');
    anchor.href = fileURL;
    anchor.download = 'preview.pdf';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  private getAcroForm(pdfDoc: PDFDocument) {
    return pdfDoc.catalog.lookup(PDFName.of('AcroForm'), PDFDict);
  }

  private getAcroFields(pdfDoc: PDFDocument) {
    const acroForm = this.getAcroForm(pdfDoc);
    if (!acroForm) {
      return [];
    }

    const fieldRefs = acroForm.lookupMaybe(PDFName.of('Fields'), PDFArray);
    if (!fieldRefs) {
      return [];
    }

    const fields = new Array(fieldRefs.size());
    for (let idx = 0, len = fieldRefs.size(); idx < len; idx++) {
      fields[idx] = fieldRefs.lookup(idx);
    }
    return fields;
  }

  private findAcroFieldByName(pdfDoc: PDFDocument, name: string) {
    const acroFields = this.getAcroFields(pdfDoc);
    return acroFields.find((acroField) => {
      const fieldName = acroField.get(PDFName.of('T'));
      return (
        (fieldName instanceof PDFString || fieldName instanceof PDFHexString) &&
        fieldName.decodeText() === name
      );
    });
  }

  private fillAcroTextField(acroField: PDFDict, text: string, font: PDFFont) {
    const rect = acroField.lookupMaybe(PDFName.of('Rect'), PDFArray);
    if (rect) {
      const width =
        rect.lookup(2, PDFNumber).asNumber() - rect.lookup(0, PDFNumber).asNumber();
      const height =
        rect.lookup(3, PDFNumber).asNumber() - rect.lookup(1, PDFNumber).asNumber();

      const MK = acroField.lookupMaybe(PDFName.of('MK'), PDFDict);
      const R = MK && MK.lookupMaybe(PDFName.of('R'), PDFNumber);
      const rotation = R ? R.asNumber() : 0;

      const N = this.singleLineAppearanceStream(font, text, rotation, width, height);
      acroField.set(PDFName.of('AP'), acroField.context.obj({N}));
    }

    // acroField.set(PDFName.of('Ff'), PDFNumber.of(1 /* Read Only */));
    acroField.set(PDFName.of('V'), PDFHexString.fromText(text));
    acroField.delete(PDFName.of('AP'));
  }

  private singleLineAppearanceStream(
    font: PDFFont,
    text: string,
    rotation: number,
    width: number,
    height: number,
  ) {
    const rotationCorrectedHeight = rotation % 90 === 0 ? width : height;

    const size = font.sizeAtHeight(rotationCorrectedHeight - 8);
    const encodedText = font.encodeText(text);
    const x = 0;
    const y = rotationCorrectedHeight - size;

    return this.textFieldAppearanceStream(
      font,
      size,
      encodedText,
      rotation,
      x,
      y,
      width,
      height,
    );
  }

  private textFieldAppearanceStream(
    font: PDFFont,
    size: number,
    encodedText: PDFHexString,
    rotation: number,
    x: number,
    y: number,
    width: number,
    height: number,
  ) {
    const dict = font.doc.context.obj({
      Type: 'XObject',
      Subtype: 'Form',
      FormType: 1,
      BBox: [0, 0, width, height],
      Resources: {Font: {F0: font.ref}},
    });

    const operators = [
      rotateDegrees(rotation),
      translate(0, rotation % 90 === 0 ? -width : 0),
      PDFOperator.of(Ops.BeginMarkedContent, [asPDFName('Tx')]),
      pushGraphicsState(),
      ...drawText(encodedText, {
        color: rgb(0, 0, 0),
        font: 'F0',
        size,
        rotate: degrees(0),
        xSkew: degrees(0),
        ySkew: degrees(0),
        x,
        y,
      }),
      popGraphicsState(),
      PDFOperator.of(Ops.EndMarkedContent)
    ];

    const stream = PDFContentStream.of(dict, operators);

    return font.doc.context.register(stream);
  }
}
