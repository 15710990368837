import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'ee-new-client-form',
  template: `
    <div class="flex flex-col justify-stretch items-stretch">
      <ee-expansion-panel header="Contact Information" class="mb-4" [expanded]="true" [collapsible]="false" [fullWidth]="true">
        <div class="flex flex-col justify-stretch items-stretch h-full w-full" [formGroup]="userDetails">
          <div class="flex flex-col md:flex-row">
            <mat-form-field class="flex flex-1 md:mr-4">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="first_name" required>
              <mat-error
                *ngIf="userDetails?.get('first_name').hasError('required')">
                Please enter your first name.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
            <mat-form-field class="flex flex-1">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="last_name" required>
              <mat-error
                *ngIf="userDetails?.get('last_name').hasError('required')">
                Please enter your last name.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
          </div>
          <div class="flex flex-col md:flex-row">
            <mat-form-field class="flex flex-1 md:mr-4">
              <mat-label>Email Address</mat-label>
              <input matInput formControlName="email" required>
              <mat-error *ngIf="userDetails?.get('email').hasError('required')">
                Please enter your email address.
              </mat-error>
              <mat-error *ngIf="userDetails?.get('email').hasError('email')">
                Please enter a valid email address.
              </mat-error>
              <mat-error *ngIf="userDetails?.get('email').hasError('emailTaken')">
                Email address is associated with an existing account.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
            <mat-form-field class="flex-1">
              <mat-label>Phone Number</mat-label>
              <input matInput mask="(000) 000-0000" formControlName="phone_number" required>
              <mat-error
                *ngIf="userDetails?.get('phone_number').hasError('required')">
                Please enter a phone number.
              </mat-error>
              <mat-hint>Required</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </ee-expansion-panel>
      <ee-expansion-panel header="Business Information" [expanded]="true" [collapsible]="false" [fullWidth]="true">
        <ee-organization-form [form]="clientDetails" [hideBillingFields]="true"></ee-organization-form>
      </ee-expansion-panel>
    </div>
  `,
  standalone: false
})
export class NewClientFormComponent implements OnInit {
  @Input()
    form: UntypedFormGroup;

  constructor() {
  }

  ngOnInit() {
  }

  get userDetails(): UntypedFormGroup {
    return this.form.controls.user_details as UntypedFormGroup;
  }

  get clientDetails(): UntypedFormGroup {
    return this.form.controls.client_details as UntypedFormGroup;
  }
}
