import {
  Component,
  Input, OnChanges,
  OnDestroy
} from '@angular/core';
import {UntypedFormArray, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {CustomField, Eviction, IntakeFormSection} from '@ee/common/models';
import {ConditionEvaluatorService, DocumentService, FormService} from '@ee/common/services';
import {MAX_FILE_UPLOAD_SIZE} from '@ee/common/constants';
import {FileValidator} from '@ee/common/validators';

@Component({
  selector: 'ee-eviction-documentation',
  template: `
    <form class="flex flex-col md:flex-row md:flex-wrap justify-start items-stretch md:items-start">
      <ng-container *ngFor="let f of section?.fields; let i = index">
        <ee-uploader *ngIf="visibility[i]" class="flex-1 md:flex-[0_1_50%]" additionalClasses="ee__standard-shadow m-4 p-4"
                     [form]="$any(documentsForm.controls[i])" [label]="f.label" [hint]="!f.link_id ? f.hint : null"
                     [downloadFileLink]="f.link_id" (downloadFormEvent)="downloadForm($event)" />
      </ng-container>
    </form>
  `,
  styles: [`
    @import 'components/color-palette';

    .card {
      margin: 1rem;
    }

    .mat-mdc-form-field {
      margin-bottom: 1rem;
    }

    .no-wrap {
      white-space: nowrap;
    }

    .errors {
      color: var(--primary-red);
      text-align: right;
    }

    .upload-wrapper {
      margin-bottom: 1rem;

    .label {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.5rem;
      padding-bottom: .5rem;
      text-align: center;
    }

    }
  `],
  standalone: false
})
export class EvictionDocumentationComponent implements OnChanges, OnDestroy {
  @Input() documentsForm: UntypedFormArray;
  @Input() claimForm: UntypedFormGroup;
  @Input() propertyForm: UntypedFormGroup;
  @Input() workflowForm: UntypedFormGroup;
  @Input() section: IntakeFormSection | undefined;
  @Input() currentStep: number; // only used for triggering ngOnChanges

  public visibility: boolean[] = [];
  public required: boolean[] = [];

  private subs: Subscription[] = [];

  constructor(private conditionEvaluator: ConditionEvaluatorService, private formService: FormService,
              private documentService: DocumentService) {
  }

  ngOnChanges() {
    if (this.propertyForm && this.claimForm && this.documentsForm) {
      this.section?.fields.forEach((s: CustomField, index: number) => {
        this.visibility[index] = true;
        this.required[index] = false;
      });

      const evictionDetails = {...this.claimForm.value, ...this.propertyForm.value};
      this.section.fields.forEach((s: CustomField, index: number) => {
        this.visibility[index] = !s.visibility_rules ||
          this.conditionEvaluator.evaluateConditions(s.visibility_rules, evictionDetails);
        if (!this.visibility[index]) {
          this.required[index] = false;
          this.documentsForm.at(index).removeValidators(Validators.required);
        } else if (s.required) {
          this.required[index] = true;
          this.documentsForm.at(index).addValidators([Validators.required,
            FileValidator.maxContentSize(MAX_FILE_UPLOAD_SIZE)]);
        }
      });
      this.documentsForm.updateValueAndValidity();
    }
  }

  public downloadForm(formLink: string): void {
    const evictionDetails = {...this.claimForm.value, ...this.propertyForm.value} as Eviction;

    // add plaintiff name
    const workflowForm = this.workflowForm.value;
    if (workflowForm.client_details?.company_name) {
      evictionDetails.company_name = workflowForm.client_details.company_name;
    }

    this.subs.push(
      this.formService.generate(formLink, evictionDetails).subscribe((res) => {
        const contentDisposition = res.headers.get('content-disposition');
        const filename = contentDisposition.split(';')[1].split('=')[1].trim();
        this.documentService.saveDocument(filename, res.body);
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach(x => x.unsubscribe());
  }


}
