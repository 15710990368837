import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConfirmDialogModel, CustomButtonTextConfirmDialog} from '@ee/common/models';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'ee-confirm-dialog',
  template: `
    <h2 mat-dialog-title>{{model.title ? model.title : 'Are you sure?'}}</h2>
    <div class="content" mat-dialog-content>
      <div class="description" *ngIf="model.description"
           [innerHTML]="model.description">
      </div>
      <mat-form-field *ngIf="model.showInput">
        <mat-label>{{model.inputPlaceholder ?? 'Please provide a reason'}}</mat-label>
        <input matInput [formControl]="$any(form.get('reason'))" [required]="model.inputRequired">
        <mat-error *ngIf="form.get('reason').dirty && form.get('reason').hasError('required')">
          Please provide a reason.
        </mat-error>
      </mat-form-field>
      <div *ngIf="model.matchingString" class="matching">
        <p class="text-center mb-4">Type <strong>{{model.matchingString}}</strong> to confirm.</p>
        <mat-form-field class="compact">
          <input class="text-center" matInput [(ngModel)]="matchingText">
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="ee-modal-actions">
      <button mat-button (click)="closeDialog(false)" cdkFocusInitial type="button">{{cancelBtnText}}</button>
      <button mat-button (click)="closeDialog(true)" color="accent" type="button"
              [disabled]="!!model.matchingString && matchingText != model.matchingString">
        {{okBtnText}}
      </button>
    </div>
  `,
  styles: [`
    .content {
      display: flex;
      flex-direction: column;

      .description {
        text-align: center;
      }

      .matching {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: stretch;
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  `],
  standalone: false
})
export class ConfirmDialogComponent {

  okBtnText: string;

  cancelBtnText: string;

  input: string;

  form: UntypedFormGroup;

  matchingText: string;

  constructor(@Inject(MAT_DIALOG_DATA) public model: ConfirmDialogModel,
              private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<ConfirmDialogComponent>) {
    const confirmationButtonText = model as CustomButtonTextConfirmDialog;

    if (model.showInput) {
      this.form = this.formBuilder.group({
        reason: ['', this.model.inputRequired ? Validators.required : []]
      });
    }

    this.okBtnText = confirmationButtonText.okBtnText ? confirmationButtonText.okBtnText : 'OK';
    this.cancelBtnText = confirmationButtonText.cancelBtnText ? confirmationButtonText.cancelBtnText : 'CANCEL';
  }

  closeDialog(choice: boolean) {
    if (this.model.showInput && choice) {
      if (this.model.inputRequired && this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }
      this.dialogRef.close(this.form.value);
    } else {
      this.dialogRef.close(choice);
    }
  }
}
