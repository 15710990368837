import {Component, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {BillableItemType, FlatFeeType} from '@ee/common/enums';
import {CountyFeeSettingsService, OrganizationService} from '@ee/common/services';
import {Observable, Subscription} from 'rxjs';
import {County, CountyFeeSetting, Tenant} from '@ee/common/models';
import forEach from 'lodash-es/forEach';

@Component({
  selector: 'ee-billing-form',
  template: `
    <div [formGroup]="billingForm" class="flex flex-col">
      <div class="flex flex-row mb-2 justify-center items-stretch">
        <mat-form-field class="compact flex-1 mr-4" subscriptSizing="fixed">
          <mat-label>Billing Entry Type</mat-label>
          <mat-select required formControlName="type">
            <mat-option [value]="billableItemType.FLAT_FEE">Flat Fee</mat-option>
            <mat-option *ngIf="!setupForm" [value]="billableItemType.TIME_ENTRY">Time Entry</mat-option>
            <mat-option [value]="billableItemType.EXPENSE">Expense</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="compact flex-1" subscriptSizing="fixed">
          <mat-label>Date Incurred</mat-label>
          <input matInput [matDatepicker]="billingPicker" formControlName="date_incurred">
          <mat-datepicker-toggle matSuffix [for]="billingPicker"></mat-datepicker-toggle>
          <mat-datepicker #billingPicker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="flex flex-row mb-2 justify-center items-stretch">
        <div class="flex-1 mr-4" *ngIf="billingForm.get('type').value === billableItemType.EXPENSE"></div>
        <mat-form-field class="flex-1 compact mr-4" subscriptSizing="fixed"
                        *ngIf="billingForm.get('type').value === billableItemType.FLAT_FEE">
          <mat-label>Flat Fee Type</mat-label>
          <mat-select formControlName="flat_fee_type">
            <mat-option [value]="flatFeeType.CUSTOM">Custom</mat-option>
            <mat-option [value]="flatFeeType.CLIENT_ATTORNEY_FEE" *ngIf="attorneyFee || isWorkflowConfig">
              Client-Specific Attorney Fee
            </mat-option>
            <mat-option *ngIf="(county && countyFeeSetting) || setupForm" [value]="flatFeeType.COUNTY_FEE">
              Calculated - County Fees
            </mat-option>
          </mat-select>
          <mat-hint *ngIf="setupForm && billingForm.get('flat_fee_type').value === flatFeeType.COUNTY_FEE">
            *More information below
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="compact flex-1" subscriptSizing="fixed"
                        *ngIf="billingForm.get('type').value !== billableItemType.TIME_ENTRY">
          <span matPrefix>$ &nbsp;</span>
          <mat-label>Amount</mat-label>
          <input matInput type="number" formControlName="amount">
        </mat-form-field>
        <mat-form-field class="compact flex-1 mr-4" subscriptSizing="fixed"
                        *ngIf="billingForm.get('type').value === billableItemType.TIME_ENTRY">
          <mat-label>Hours</mat-label>
          <input matInput type="number" formControlName="qty">
        </mat-form-field>
        <mat-form-field class="compact flex-1" subscriptSizing="fixed"
                        *ngIf="billingForm.get('type').value === billableItemType.TIME_ENTRY">
          <span matPrefix>$ &nbsp;</span>
          <mat-label>Rate</mat-label>
          <input matInput type="number" formControlName="rate">
        </mat-form-field>
      </div>
      <div class="flex flex-row justify-stretch items-stretch mb-4">
        <mat-form-field class="compact flex-1">
          <mat-label>Description</mat-label>
          <input #billingDescriptionInput matInput formControlName="description"
                 [matAutocomplete]="billingDescriptionAutoComplete"
                 [matAutocompleteDisabled]="!billingDescriptions$ || (billingDescriptions$ | async)?.length < 1">
          <mat-hint *ngIf="setupForm && billingForm.get('flat_fee_type').value === flatFeeType.COUNTY_FEE">
            [COUNTY] will be overridden with the actual county name
          </mat-hint>
        </mat-form-field>
        <mat-autocomplete #billingDescriptionAutoComplete="matAutocomplete">
          <mat-option *ngFor="let option of (billingDescriptions$ | async)" [value]="option">
            {{option}}
          </mat-option>
          <mat-option [value]="billingDescriptionInput.value">Add New Description</mat-option>
        </mat-autocomplete>
      </div>
      <mat-checkbox *ngIf="!setupForm" formControlName="save_description">Save description for later</mat-checkbox>
      <div *ngIf="setupForm && billingForm.get('flat_fee_type').value === flatFeeType.COUNTY_FEE">
        *County-specific billable items will only apply when a valid county has been selected and an
          applicable county fee has been set up.
      </div>
    </div>
  `,
  styles: [`
    mat-form-field.mw-200 {
      width: 200px;
      margin-right: 1rem;
    }
  `],
  standalone: false
})
export class BillingFormComponent implements OnChanges, OnDestroy {
  billableItemType = BillableItemType;
  flatFeeType = FlatFeeType;

  @Input() billingForm: UntypedFormGroup;

  @Input() setupForm = false;

  @Input() tenants: Tenant[];

  @Input() county: County;

  @Input() attorneyFee: number | undefined;

  @Input() isWorkflowConfig = false;

  countyFeeSetting: CountyFeeSetting;

  billingDescriptions$: Observable<string[]>;
  private subs: Subscription[] = [];

  constructor(private organizationService: OrganizationService,
              private countyFeeSettingsService: CountyFeeSettingsService) {
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }

  ngOnChanges(changes: SimpleChanges) {
    this.subs.push(this.billingForm.get('type').valueChanges.subscribe((newType) => this.handleTypeChange(newType)));
    this.subs.push(this.billingForm.get('flat_fee_type').valueChanges.subscribe((newType) => this.handleFlatFeeTypeChange(newType)));
    this.billingDescriptions$ = this.organizationService.getBillingDescriptionHistory();
    if (this.county && changes['county']?.previousValue?.id !== changes['county']?.currentValue?.id) {
      this.subs.push(this.countyFeeSettingsService.getByCounty(this.county).subscribe({
        next: (setting: CountyFeeSetting | null) => {
          this.countyFeeSetting = setting;
        },
        error: () => {
          this.countyFeeSetting = null;
        }
      }));
    }
  }

  billingOptionChanged($event) {
    this.handleTypeChange($event.value);
  }

  public handleTypeChange(type: BillableItemType) {
    this.resetForm(this.billingForm, type);
  }

  private handleFlatFeeTypeChange(newFlatFeeType: FlatFeeType) {
    const amount = this.billingForm.get('amount');
    const description = this.billingForm.get('description');
    if (newFlatFeeType === this.flatFeeType.CLIENT_ATTORNEY_FEE) {
      if (this.attorneyFee) { // In case of workflow step configuration we don't get client specific attorney fee
        amount.setValue(this.attorneyFee);
      } else {
        amount.setValue(0);
        amount.disable();
      }
      description.setValue('Attorney Fee');
    } else if (newFlatFeeType === this.flatFeeType.COUNTY_FEE && (this.setupForm || (this.county && this.countyFeeSetting && this.tenants))) {
      description.setValue(`${newFlatFeeType} - ${this.county?.name ? this.county.name : '[COUNTY]'}`);
      amount.setValue(0);
      amount.disable();
      if (!this.setupForm) {
        amount.setValue(BillingFormComponent.calculateCountyFeeAmount(this.countyFeeSetting, this.tenants));
      }
    } else {
      amount.setValue(null);
      amount.enable();
      description.setValue('');
    }
  }

  /**
   * Generates a county fee based on the county fee settings
   *
   * @param countySetting
   * @param tenants
   * @private
   */
  public static calculateCountyFeeAmount(countySetting: CountyFeeSetting, tenants: Tenant[]): number |  null {
    if (!countySetting?.fee || !tenants?.length) {
      return null;
    }
    let total = countySetting.fee;
    if (countySetting.additional_tenant_fee && tenants.length > 1) {
      tenants.forEach((tenant: Tenant, index: number) => {
        if (index > 0) {
          total += countySetting.additional_tenant_fee;
        }
      });
    }
    return total;
  }

  private resetForm(form: UntypedFormGroup, type: BillableItemType) {
    forEach(form.controls, (value, key: string) => {
      if (key !== 'type' && key !== 'date_incurred' && key !== 'description') {
        if (type === this.billableItemType.TIME_ENTRY && (key === 'qty' || key === 'rate')) {
          form.get(key).setValue(null);
          form.get(key).setValidators(Validators.required);
          form.get(key).updateValueAndValidity();
        } else if (type === this.billableItemType.FLAT_FEE && key === 'flat_fee_type') {
          form.get(key).setValue(this.flatFeeType.CUSTOM);
          form.get(key).setValidators([Validators.required]);
          form.get(key).updateValueAndValidity();
        } else {
          if (key === 'amount') {
            form.get(key).setValue(null);
            form.get(key).enable();
          }
          form.get(key).clearValidators();
          form.get(key).updateValueAndValidity();
        }
      }
    });
    form.get('save_description').setValue(false);
    if (type !== this.billableItemType.TIME_ENTRY) {
      form.get('amount').setValidators(Validators.required);
      form.get('amount').updateValueAndValidity();
    }
    form.get('description').setValue('');

    form.updateValueAndValidity();
  }

}
