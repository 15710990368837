import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ConflictingCases, EmailDetails,
  IntakeEviction,
  IntakeEvictionList,
  LandingPageVisit,
  ProcessIntakeEviction
} from '@ee/common/models';
import {IntakeEvictionType} from '@ee/common/enums';

@Injectable({ providedIn: 'root' })
export class IntakeEvictionService {
  public resourceUrl: string;

  constructor(private http: HttpClient, @Inject('environment') private environment) {
    this.resourceUrl = this.environment.api_prefix + 'api/intake-evictions';
  }

  updatePageLoadVisits(landingPageVisit: LandingPageVisit): Observable<LandingPageVisit> {
    return this.http.put<LandingPageVisit>(`${this.environment.api_prefix}api/page-visits`, landingPageVisit);
  }

  loadIntakeEvictionList(): Observable<IntakeEvictionList[]> {
    return this.http.get<IntakeEvictionList[]>(`${this.environment.api_prefix}api/intake-evictions/list`);
  }

  saveIntakeEviction(type: IntakeEvictionType, formData: FormData): Observable<string | undefined> {
    let url = this.resourceUrl;

    if (type === IntakeEvictionType.INTAKE_FORM) {
      url += '/intake-form';
    }

    return this.http.post(url, formData, {responseType: 'text'});
  }

  bulkImportIntakeEviction(evictions: any) {
    return this.http.post(this.resourceUrl + '/bulk-import', evictions);
  }

  findIntakeEvictionConflicts(id: string, clientId: string): Observable<ConflictingCases> {
    return this.http.get<ConflictingCases>(`${this.resourceUrl}/${id}/client/${clientId}/conflicts`);
  }

  addLandingPageVisit(attorneyId: string): Observable<any> {
    return this.http.post<LandingPageVisit>(`${this.environment.api_prefix}api/page-visits/${attorneyId}`, null);
  }

  processIntakeEviction(id: string, value: ProcessIntakeEviction): Observable<any> {
    return this.http.put(`${this.resourceUrl}/process/${id}`, value, {responseType: 'text'});
  }

  generateFillableFormDetails(intakeEviction: IntakeEviction): Observable<Map<string, string>> {
    return this.http.post<Map<string, string>>(`${this.resourceUrl}/form-fill-details`, intakeEviction);
  }

  loadIntakeEviction(id: string): Observable<IntakeEviction> {
    return this.http.get<IntakeEviction>(`${this.resourceUrl}/${id}`);
  }

  updateIntakeEvictionDetails(intakeEviction: IntakeEviction): Observable<IntakeEviction> {
    return this.http.put<IntakeEviction>(this.resourceUrl, intakeEviction);
  }

  rejectIntakeEviction(id: string, emailDetails: EmailDetails) {
    return this.http.put(`${this.resourceUrl}/process/${id}/reject`, emailDetails);
  }

  deleteIntakeEviction(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.resourceUrl}/${id}`);
  }

  initScraCheck(intakeEvictionId: string, tenantId: string): Observable<any> {
    return this.http.put(`${this.resourceUrl}/scra-check/${intakeEvictionId}/tenant/${tenantId}`, undefined);
  }
}
