import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { IntakeEvictionService } from './services/intake-eviction.service';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EvictionIntakeModule } from '@ee/common/eviction-intake';
import { MatIconModule } from '@angular/material/icon';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LoadingModule } from '@ee/common/loading';
import { LayoutModule } from '@angular/cdk/layout';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';

@NgModule({ declarations: [AppComponent],
  bootstrap: [AppComponent], imports: [BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MatToolbarModule,
    RouterModule.forChild([]),
    EvictionIntakeModule.forRoot(environment),
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatLuxonDateModule,
    MatListModule,
    LoadingModule,
    LayoutModule
  ],
  providers: [
    IntakeEvictionService,
    { provide: 'environment', useValue: environment }, provideHttpClient(withInterceptorsFromDi()),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: 'dynamic' } },
  ]
})
export class AppModule {
  constructor() {}
}
